import React from 'react';
import profileImage from '../assets/images/profile.png';
import { Link } from 'react-router-dom';

export default function FooterInvite() {
    return (
        <section className="section-cta py-5 bg-primary text-white">
            <div className="container">
                <div className="text-center">
                    <img className="profile-small d-inline-block mx-auto rounded-circle mb-2" src={profileImage} alt="Mike Thompson" />
                </div>
                <h3 className="section-title font-weight-bold text-center mb-2">Interested in hiring me for your project?</h3>
                <div className="section-intro mx-auto text-center mb-3">
                    Looking for an experienced full-stack developer to build your web app or ship your software product? To start an initial chat use the <Link className="link-on-bg" to="/contact">form on the contact page</Link>.
		            </div>
                <div className="text-center">
                    <Link to="/contact" className="theme-btn-on-bg btn">Let's Talk</Link>
                </div>
            </div>
        </section>
    );
}