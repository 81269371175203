import React from 'react';
import DefaultLayout from './DefaultLayout';
import { Link } from 'react-router-dom'

export default function GenericError(props) {
    return <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()} hideFooterInvite />
}

const getHeader = () => {
    return (
        <div className="header-intro theme-bg-primary text-white py-5">
            <div className="container">
                <h2 className="page-heading mb-0">Error</h2>
            </div>
        </div>
    );
}

const getContent = () => {
    return (
        <section className="section py-5">
            <div className="container">
                <p className="text-center" style={{ fontSize: '1.2rem' }}>An unexpected error occurred, sorry for the inconvenience. Try going back to the <Link to="/">home page</Link></p>
            </div>
        </section>
    );
}
