import React, { useEffect } from 'react';
import TopBar from './TopBar';
import initNavUnderline from '../utils/initNavUnderline';
import FooterInvite from './FooterInvite';

export default function DefaultLayout(props) {
    useEffect(() => initNavUnderline());

    return (
        <div>
            <header>
                <TopBar location={props.location} />
                {props.headerChildren}
            </header>
            <div>
                {props.contentChildren}
            </div>
            {!props.hideFooterInvite && <FooterInvite />}
            <footer className="footer text-light text-center py-2">
                <small className="copyright">Copyright &copy; {new Date().getFullYear()} Michael Thompson</small>
            </footer>
        </div>
    );
}
