import React from 'react';
import DefaultLayout from '../DefaultLayout';
import ProjectHeader from './ProjectHeader';
import CenteredImage from '../CenteredImage';
import GoBackToProjects from './GoBackToProjects';
import { Link } from 'react-router-dom';

import img from '../../assets/images/projects/swol.jpg';
import imgHealthTrackerDiagram from '../../assets/images/projects/healthtracker-aux-diagram.png';
import imgSnapshotDiagram from '../../assets/images/projects/snapshot-diagram.png';
import imgEngagementDiagram from '../../assets/images/projects/engagement-diagram.png';
import StayWellBackground from './StayWellBackground';
import StyledSectionTitle from '../StyledSectionTitle';
import StyledParagraphListLabel from '../StyledParagraphListLabel';
import StyledProjectSection from '../StyledProjectSection';
import StyledUl from '../StyledUl';
import StyledLi from '../StyledLi';

export default function StayWellOnline(props) {
    return (
        <div>
            <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()} />
        </div>
    );
}

const getHeader = () => {
    return <ProjectHeader title="Project: StayWell Online" />;
}

const getContent = () => {
    return (
        <>
            <GoBackToProjects />
            <StyledProjectSection>
                <CenteredImage src={img} alt="StayWell" />

                <StayWellBackground />

                <StyledSectionTitle>StayWell Online</StyledSectionTitle>
                <p>My primary responsibilities have included maintaining and extending our primary external facing web application StayWell Online (later called StayWell Portal), implementing client customizations, and building/maintaining miscellaneous internal applications.</p>

                <StyledParagraphListLabel>Some things I took part in while working on StayWell Online:</StyledParagraphListLabel>
                <StyledUl>
                    <StyledLi>Participated on the development team responsible for rewriting the StayWell Online front end and releasing the next major version</StyledLi>
                    <StyledLi>Worked with existing code and a third party SAML library to implement inbound and outbound SSO implementations.</StyledLi>
                    <StyledLi>Wrote C# SAML wrapper utility, enabling our team to implement new outbound SSO implementations with only a few lines of code.</StyledLi>
                    <StyledLi>Created an internal tool used to generate excel documents containing all default localized site text and other related information to improve the client implementation process.</StyledLi>
                    <StyledLi>Participated in a large project to migrate StayWell Online between MediMedia companies (Krames Staywell to StayWell Health Management). This included migrating code from StarTeam source control to TFS, as well as contributing to the creation of new automated build processes.</StyledLi>
                    <StyledLi>Created an internal tool used to automate .NET GAC installations used by StayWell Online.</StyledLi>
                    <StyledLi>Created an internal tool to automate setting up all client websites on a developer machine. The tool automates building the site via msbuild, creates the site node in IIS, creates a virtual directory to a remote document share, applies credentials, adds the new IIS site nodes to an application pool and adds an appropriate hosts entry - all with detailed log output. After all the sites are setup it also employs Selenium Web Driver to automate testing that all client sites load up without errors.</StyledLi>
                    <StyledLi>Participated on a team responsible for implementing an integration with <a href="http://www.fitbit.com">Fitbit</a>. My role on this team was to create a responsive ajax driven UI that consumes our newly created web service (which communicates with Fitbit via their Rest API and also with our own database). I also contributed to the creation of the web services that communicate with Fitbit, as well as our internal database structure.</StyledLi>
                    <StyledLi>Assisted with migration effort when Krames StayWell merged back with Staywell Health Management. Helped train new team members on internal and external facing applications and processes involved in maintenance as well as new development.</StyledLi>
                    <StyledLi>Participated on team responsible for merging our existing product into Sitecore (.NET enterprise CMS solution).</StyledLi>
                    <StyledLi>Built SAML test utility to allow simpler testing and implementation and troubleshooting of all things SSO. Includes features to generate public certificates, base64 encode/decode payloads, encrypt/decrypt payloads, validate signatures and log test users in.</StyledLi>
                    <StyledLi>Participated in architecture and construction of new features and subsystems involving technologies new to the team (iFrame plugins, MongoDB, KnockoutJS, RequireJS, RestSharp, HttpClient and others)</StyledLi>
                    <StyledLi>Assisted in solving multiple difficult third party compiled code bugs by analyzing decompiled assemblies.</StyledLi>
                    <StyledLi>From 2016 to 2018 I was Team Lead for a team comprised of both US based and offshore developers and QA team members.</StyledLi>
                </StyledUl>

                <StyledSectionTitle>Feature Design and Architecture</StyledSectionTitle>
                <p>While working on StayWell Portal I had the opportunity to design a number of features and either build them myself or work on them with my team while also directing the work as a team lead. Below are some of the highlights.</p>

                <StyledSectionTitle>HealthTracker</StyledSectionTitle>
                <p>The goal with this project was to integrate with a fitness and health data aggregation company called <a href="https://validic.com/">Validic</a>. By integrating with Validic we were able to provide our users the opportunity to track a variety of health related data like steps and weight and integrate this data into several other features within our application like badges, incentives, self directed coaching programs and others.</p>
                <p>Our integration consisted of a UI to allow the users to authorize data transfer and connect physical devices to the data collection service and an API to do the actual data transfer from Validic's system to StayWell's system on a real time basis.</p>
                <StyledParagraphListLabel>Five main challenges of this project:</StyledParagraphListLabel>
                <StyledUl>
                    <StyledLi>Receive multiple API transactions per user per day for tens of thousands of users in a performant way</StyledLi>
                    <StyledLi>Make the data collection process automatically correct for any Validc API outages or network issues</StyledLi>
                    <StyledLi>Structure the saved data and internal APIs for easy use by multiple internal features/applications</StyledLi>
                    <StyledLi>Provide administrative tools for monitoring API performance, pulling user and client data, etc</StyledLi>
                    <StyledLi>Create thorough documentation for developers to be able to implement use of this data for their features and to perform maintenance on the Validic integration itself</StyledLi>
                </StyledUl>
                <p>The most interesting part of this project for me was the performance tuning. During data pulls there are 7 types of data, each with 2 variants, for a total of 14 different API endpoints, each endpoint requiring paging of requests. Rather than doing all the work synchronously I implemented the data pull logic using multi-threading. That by itself is not terribly interesting, but I also throttled calls using a <a href="https://docs.microsoft.com/en-us/dotnet/standard/threading/semaphore-and-semaphoreslim">semaphore</a> to ensure that the optimal amount of work was always being done without bottoming out the server's resources and without lulls that could have been caused by queing up chunks of work and running each chunk till done.</p>
                <StyledParagraphListLabel>Allowing near real-time use of new data as it comes in with other features in our application uses a queue and a windows service to offload the work. Here is one of the diagrams from our documentation that illustrates this process: </StyledParagraphListLabel>
                <CenteredImage src={imgHealthTrackerDiagram} alt="HealthTracker Auxiliary Tasks" />


                <StyledSectionTitle>Snapshot</StyledSectionTitle>
                <p>The goal with the Snapshot project was to create a simple framework for developers to easily wire up nightly report data that can be viewed in our admin tool and also emailed to a specified list of users. Before implementing this feature each team had their own method of creating report data that was often inconsistent and error prone. After the Snapshot feature was completed and teams implemented it with their features, it was easy to get a high level view of the status and possible concerns there might be on a daily basis.</p>
                <StyledParagraphListLabel>Because reflection is used to find all implementations, all a develop has to do to implement this with a feature is to add a value to an enum and implement a base class. This diagram encapsulates how each component of this feature relates:</StyledParagraphListLabel>
                <CenteredImage src={imgSnapshotDiagram} alt="Snapshot Diagram" />

                <StyledSectionTitle>Communities</StyledSectionTitle>
                <p>Communities was a feature built to act as a sort of topic-based user discussion forum that also ties into other features within the application. It can be used as a stand-alone page or as part of the Group Coaching feature (see Group Coaching below). The challenge with this project was to implement it in such a way that an administrator had a number of customization options that allowed for things like cross-client forums vs single-client versions and custom content on the dashboard for the community.</p>

                <StyledSectionTitle>Group Coaching</StyledSectionTitle>
                <p>Group Coaching is a set-length program that involves weekly activities, group meeting live meeting sessions and topical discussion forums (see Communities above). An adminstrator has the ability to configure a Group Coaching program including the topic, content for discussion each week, setup of group live meeting times each week, program completion criteria, etc. The feature has also has tools tied into the applications existing coaching platform that allow coaches to track individual participation progress.</p>

                <StyledSectionTitle>Onpoint</StyledSectionTitle>
                <p>Onpoint was a feature meant to bundle together several other features for the specific topic of Diabetes. Features bundled include Group Coaching, Communities, Support Coaching as well as device data tracking (see HealthTracker above).</p>

                <StyledSectionTitle>Engagement</StyledSectionTitle>
                <p>The Engagement platform is a set of functionality that allows all other features within our application to manage email and sms messages to paricipants. Technically this was Engagement 2.0. The first version was written by another team early in the application's life before all requirements were known, and eventually the product owners requested additional functionality that required a redesign/rewrite of this feature.</p>
                <StyledParagraphListLabel>Design philosophy:</StyledParagraphListLabel>
                <StyledUl>
                        <StyledLi>Logic that is common between all implementations should be centralized.</StyledLi>
                        <StyledLi>Implementing email batch sending for a new feature and maintaining an existing implementation should be straight forward. All code for an implementation should be centralized in its scheduler logic implementation.</StyledLi>
                        <StyledLi>All functionality except the logic that determines the content and list of participants to send to should be shared and should be implementation agnostic.</StyledLi>
                        <StyledLi>The primary unit of work for the system, regardless of the implementation, is a batch. At a minimum, a batch consists of one piece of content and a list of participants to send to.</StyledLi>
                        <StyledLi>All aspects of the system should be able to be exercised from test harnesses, including triggering of error conditions, success conditions and sending of actual messages to a provided email address or phone number.</StyledLi>
                        <StyledLi>All logic should be wrapped in thorough error handling and should log verbosely any exceptional conditions.</StyledLi>
                </StyledUl>
                <StyledParagraphListLabel>System Diagram:</StyledParagraphListLabel>
                <CenteredImage src={imgEngagementDiagram} alt="Engagement Platform Diagram" />
                
                <StyledSectionTitle>StayWell in {new Date().getFullYear()}</StyledSectionTitle>
                <p>Since StayWell aquired Provata Health in 2018, we have been working on porting features to a new react/node/java application. Eventually the goal is to transition all our existing clients to the new application. See <Link to="/projects/my-staywell">MyStaywell Platform</Link></p>
            </StyledProjectSection>
        </>
    );
}
