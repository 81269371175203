import React from 'react';
import DefaultLayout from '../DefaultLayout';
import ProjectHeader from './ProjectHeader';
import GoBackToProjects from './GoBackToProjects';

import '../../assets/css/carousel-fix.css';

import img from '../../assets/images/projects/lochner.jpg';
import imgPeoria from '../../assets/images/projects/peoria_eastern_bypass.jpg';
import CenteredImage from '../CenteredImage';

export default function Lochner(props) {
    return (
        <div>
            <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()} />
        </div>
    );
}

const getHeader = () => {
    return <ProjectHeader title="Project (full-time position): Lochner" />;
}

const getContent = () => {
    return (
        <>
            <GoBackToProjects />
            <section className="project-wrapper single-col-max-width py-5 px-4 mx-auto">
                <div className="section-row">
                    <CenteredImage src={img} alt="Lochner" />
                    <h3 className="section-title">Project Background</h3>
                    <p>While working full-time at Lochner I helped with a re-write of the company intranet and created an informational site for a client project.</p>
                    <CenteredImage src={imgPeoria} alt="Peoria Eastern Bypass" />
                </div>
            </section>
        </>
    );
}
