import React from 'react';
import { NavLink } from 'react-router-dom';

export default function TopBar(props) {
    return (
        <div className="top-bar theme-bg-primary-darken">
            <div className="container-fluid">
                <nav className="navbar navbar-expand-lg navbar-dark position-relative">
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse text-uppercase" id="navigation">
                        <ul className="navbar-nav ml-lg-auto">
                            {getNavLinkItem(props, 'About', '/')}
                            {getNavLinkItem(props, 'Projects', '/projects')}
                            {/* {getNavLinkItem(props, 'Blog', '/blog')} */}
                            {getNavLinkItem(props, 'Resume', '/resume')}
                            {getNavLinkItem(props, 'Contact', '/contact')}
                            {/* <li className="nav-item mr-lg-3">
                                    <a className="nav-link" href="talks.html">Talks</a>
                                </li>
                                <li className="nav-item mr-lg-3">
                                    <a className="nav-link" href="blog.html">Blog</a>
                                </li>
                                <li className="nav-item mr-lg-3">
                                    <a className="nav-link" href="resume.html">Resume</a>
                                </li>
                                <li className="nav-item mr-lg-3">
                                    <a className="nav-link" href="contact.html">Contact</a>
                                </li> */}
                            {/* <li className="nav-item dropdown mr-0">
                                    <a className="nav-link dropdown-toggle" href="#" id="navigationLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Pages
								    </a>
                                    <div className="dropdown-menu dropdown-menu-right text-capitalize shadow-lg" aria-labelledby="navigationLink">
                                        <a className="dropdown-item" href="project.html">Project Case Study (1 Column)</a>
                                        <a className="dropdown-item" href="project-alt.html">Project Case Study (2 Columns)</a>
                                        <a className="dropdown-item" href="blog-post.html">Blog Post</a>
                                    </div>
                                </li> */}
                        </ul>
                        <span id="slide-line"></span>
                    </div>
                </nav>
            </div>
        </div>
    );
}

const getNavLinkItem = (props, linkText, relativePath) => {
    let activeClass = props.location.pathname === relativePath ? ' active' : '';
    return (
        <li className={`nav-item mr-lg-3${activeClass}`}>
            <NavLink {...(relativePath === '/' ? { exact: true } : {})} className="nav-link" to={relativePath} activeClassName="active">{linkText}</NavLink>
        </li>
    );
}
