import React from 'react';
import DefaultLayout from '../DefaultLayout';
import ProjectHeader from './ProjectHeader';
import CenteredImage from '../CenteredImage';
import GoBackToProjects from './GoBackToProjects';

import imgRumor from '../../assets/images/projects/rumor.jpg';
import imgAcademica from '../../assets/images/projects/academica.jpg';
import imgAries from '../../assets/images/projects/aries.jpg';
import imgBodyfit from '../../assets/images/projects/bodyfitwellness.jpg';
import imgDailybread from '../../assets/images/projects/dailybread.jpg';
import imgNerveci from '../../assets/images/projects/nerveci.jpg';
import imgOCTanner from '../../assets/images/projects/octanner.jpg';
import imgOnetel from '../../assets/images/projects/onetel.jpg';
import imgDisplayBoys from '../../assets/images/projects/display_boys.jpg';

export default function Project(props) {
    return (
        <div>
            <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()} />
        </div>
    );
}

const getHeader = () => {
    return <ProjectHeader title="Rumor Advertising Miscellaneous Projects" />;
}

const getContent = () => {
    return (
        <>
            <GoBackToProjects />
            <section className="project-wrapper single-col-max-width py-5 px-4 mx-auto">
                <div className="section-row">
                    <CenteredImage src={imgRumor} alt="Rumor Advertising" />
                    <h3 className="section-title">Project Background</h3>
                    <p>While employed full time at Rumor Advertising I worked on a number of projects using a variety of technologies with many different goals.
                    Here I'll list a few of the miscellaneous projects that are not included on other pages.</p>
                </div>

                <MiscProj company="Academica West" img={imgAcademica}>
                    <p>Goal: informational site and a lead generation form.</p>
                    <p>I used a custom PHP template engine I wrote to facilitate quick construction of this site.</p>
                </MiscProj>

                <MiscProj company="Aries Capital Partners" img={imgAries}>
                    <p>Goal: news content management.</p>
                    <p>For this project I built a user login and a simple content manager for adding, removing and editing news items.</p>    
                </MiscProj>

                <MiscProj company="Bodyfit Wellness" img={imgBodyfit}>
                    <p>Goal: a simple interface to connect Bodyfit's website to their gift certificate purchase page at paypal.com.</p>
                    <p>This project was a standard paypal integration with a form that accepts the gift certificate amount, recipient's name, sender's name and forwards that information on to paypal to complete the transaction.</p>
                </MiscProj>

                <MiscProj company="Dailybread" img={imgDailybread}>
                    <p>Goal: informational site and a lead generation form.</p>
                    <p>I used a custom PHP template engine I wrote to facilitate quick construction of this site.</p>
                </MiscProj>
                
                <MiscProj company="Display Boys" img={imgDisplayBoys}>
                    <p>Goal: build content management for Display Boys' flash website.</p>
                    <p>Created a simple tool to manage data to be displayed and integrated content into flash site using javascript hooks.</p>
                </MiscProj>

                <MiscProj company="Nerve Custom Integrators" img={imgNerveci}>
                    <p>Goal: add an image gallery to Nerve's placeholder website.</p>
                    <p>I used a free flash image gallery called SimpleViewer.</p>
                </MiscProj>

                <MiscProj company="OC Tanner" img={imgOCTanner}>
                    <p>Goal: implement designs for a prototype of O.C. Tanner's new program management software.</p>
                    <p>Although the prototype was built primarily for displaying the visual template I was asked to implement functionality for a few pages in the site so they could get a good feel for how things would look and feel.</p>
                </MiscProj>

                <MiscProj company="OneTel" img={imgOnetel}>
                    <p>Goal: Create an informational website based on Photoshop documents.</p>
                </MiscProj>
            </section>
        </>
    );
}

const MiscProj = (props) => {
    return (
        <div className="section-row">
            <CenteredImage src={props.img} alt={props.company} />
            <h3 className="section-title">{props.company}</h3>
            {props.children}
        </div>
    );
}
