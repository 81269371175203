import React, { useState } from 'react' // eslint-disable-line
import DefaultLayout from './DefaultLayout'
import profileImage from '../assets/images/profile.png'
import { useForm } from 'react-hook-form'
import './Contact.style.css'
import axios from 'axios'
import {Link, useHistory} from 'react-router-dom'

export default function Contact(props) {
    return <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={ContactContent()} hideFooterInvite />
}

const getHeader = () => {
    return (
        <div className="header-intro theme-bg-primary text-white py-5">
            <div className="container">
                <h2 className="page-heading mb-0">Contact</h2>
            </div>
        </div>
    )
}

const ContactContent = () => {
    const history = useHistory() // eslint-disable-line
    const [loading, setLoading] = useState(false)
    const [sent, setSent] = useState(false)
    const { register, handleSubmit, errors, setValue } = useForm({ mode: 'onBlur' }) // eslint-disable-line
    const onSubmit = data => {
        console.log('data to send: ', data)
        setLoading(true)
        axios.post('/api/Contact/SubmitMessage', data)
            .then(response => {
                console.log('response: ', response)
                setSent(true)
                setLoading(false)
            })
            .catch((err) => {
                //history.push('/sorry')
                console.log('error: ', err);
                setLoading(false)
            })
    }
    // useEffect(() => {
    //     setValue('name', 'Some Dude')
    //     setValue('email', 'some.dude@test.com')
    //     setValue('message', 'Some text here.\n\nAnd here\'s some text after a newline.')
    // }, [])


    return (
        <section className="section py-5">
            <div className="container">
                <div className="row">
                    <div className="contact-intro col-lg-8 mx-lg-auto mb-5 text-center">

                        <img className="profile-small d-inline-block mx-auto rounded-circle mb-3" src={profileImage} alt="Mike Thompson" />

                        <div className="speech-bubble bg-white p-4 p-lg-5 shadow-sm">
                            <p className="text-left mb-3">If you are interested in hiring me please use the form below to get in touch. Want to know how I work and what I can offer? Check out my <Link to="/projects">projects</Link> and <Link to="/resume">resume</Link>.</p>
                            <h6 className="font-weight-bold text-center mb-3">You can also find me on the following channels</h6>

                            <ul className="social-list-color list-inline mb-0">
                                {/* <li className="list-inline-item mb-3"><a className="twitter" href="#"><i className="fab fa-twitter fa-fw"></i></a></li> */}
                                <li className="list-inline-item mb-3"><a className="linkedin" href="https://www.linkedin.com/in/michael-thompson-8a850b3/" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in fa-fw"></i></a></li>
                                <li className="list-inline-item mb-3"><a className="github" href="https://github.com/mikey-t" target="_blank" rel="noopener noreferrer"><i className="fab fa-github-alt fa-fw"></i></a></li>
                                <li className="list-inline-item mb-3"><a className="stack-overflow" href="https://stackoverflow.com/users/1072277/mikeyt" target="_blank" rel="noopener noreferrer"><i className="fab fa-stack-overflow fa-fw"></i></a></li>
                                {/* <li className="list-inline-item mb-3"><a className="medium" href="#"><i className="fab fa-medium-m fa-fw"></i></a></li> */}
                                {/* <li className="list-inline-item mb-3"><a className="codepen" href="#"><i className="fab fa-codepen fa-fw"></i></a></li> */}
                                {/* <li className="list-inline-item mb-3"><a className="facebook" href="#"><i className="fab fa-facebook-f fa-fw"></i></a></li> */}
                                {/* <li className="list-inline-item"><a className="instagram" href="#"><i className="fab fa-instagram fa-fw"></i></a></li> */}
                            </ul>
                        </div>

                    </div>
                    {sent && <div className="contact-intro col-lg-8 mx-lg-auto mb-5 text-center">
                        <div className="speech-bubble bg-white p-4 p-lg-5 shadow-sm"><p>Thanks!</p></div>
                    </div>}
                    {!sent && <form id="contact-form" className="contact-form col-lg-8 mx-lg-auto" method="post" action="" onSubmit={handleSubmit(onSubmit)}>
                        <h3 className="text-center mb-3">Get In Touch</h3>
                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label className="sr-only" htmlFor="name">Name</label>
                                <input type="text" className={`form-control${errors.name ? ' invalid' : ''}`} id="name" name="name" placeholder="Name"
                                    minLength="2" required aria-required="true" ref={register({ required: true, minLength: 2 })} disabled={loading} />
                                {errors.name && errors.name.type === 'required' && <span className="invalid">Name is required</span>}
                                {errors.name && errors.name.type === 'minLength' && <span className="invalid">Name must have at least 2 characters</span>}
                            </div>
                            <div className="form-group col-md-6">
                                <label className="sr-only" htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="email" name="email"
                                    placeholder="Email"
                                    required aria-required="true"
                                    ref={register({ required: true, pattern: new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i) })}
                                    disabled={loading} />
                                {errors.email && errors.email.type === 'required' && <span className="invalid">Email is required</span>}
                                {errors.email && errors.email.type !== 'required' && <span className="invalid">Please enter a valid email</span>}
                            </div>
                            <div className="form-group col-12">
                                <label className="sr-only" htmlFor="message">Your message</label>
                                <textarea className="form-control" id="message" name="message" placeholder="Enter your message" rows="10" required aria-required="true" ref={register({ required: true })} disabled={loading}></textarea>
                                {errors.message && <span className="invalid">Message is required</span>}
                            </div>
                            <div className="form-group col-12">
                                <button type="submit" className="btn btn-block btn-primary py-2" disabled={loading}>{loading ? 'Sending...' : 'Send It'}</button>
                            </div>
                        </div>
                    </form>}
                </div>
            </div>
        </section>
    )
}
