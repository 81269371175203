import $ from 'jquery';
import imagesLoaded from 'imagesloaded';
import Isotope from 'isotope-layout';
import jQueryBridget  from 'jquery-bridget';

export default function () {
    /* ======= Isotope plugin ======= */
    /* Ref: http://isotope.metafizzy.co/ */
    // init Isotope
    
    jQueryBridget( 'isotope', Isotope, $ );
    imagesLoaded.makeJQueryPlugin( $ );
    
    let $container = $('.isotope');
    
    $container.imagesLoaded(function () {
        $('.isotope').isotope({
            itemSelector: '.isotope-item',
            layoutMode: 'fitRows'
        });
    });
    
    // filter items on click
    $('#filters').on( 'click', '.type', function() {
        let filterValue = $(this).attr('data-filter');
      $container.isotope({ filter: filterValue });
    });
    
    // change is-checked class on buttons
    $('.filters').each( function( i, typeGroup ) {
        let $typeGroup = $( typeGroup );
        $typeGroup.on( 'click', '.type', function() {
          $typeGroup.find('.active').removeClass('active');
          $( this ).addClass('active');
        });
    });
}