import React from 'react'
import { Route, Switch } from 'react-router'
import About from './components/About'
import Projects from './components/Projects'
import Blog from './components/Blog' // eslint-disable-line
import Resume from './components/Resume'
import Contact from './components/Contact'
import RumorMisc from './components/projects/RumorMisc'
import InteriorArchitects from './components/projects/InteriorArchitects'
import NotFound from './components/NotFound'
import CankerComplete from './components/projects/CankerComplete'
import DZNuts from './components/projects/DZNuts'
import Brahma from './components/projects/Brahma'
import KrosswoodDoors from './components/projects/KrosswoodDoors'
import Foodinsurance from './components/projects/Foodinsurance'
import Evolutioncard from './components/projects/Evolutioncard'
import Lochner from './components/projects/Lochner'
import FatBobsGarage from './components/projects/FatBobsGarage'
import SilverSocket from './components/projects/SilverSocket'
import DevAutoLogin from './components/projects/DevAutoLogin'
import MediaManager from './components/projects/MediaManager'
import SlackDarkTheme from './components/projects/SlackDarkTheme'
import StayWellOnline from './components/projects/StayWellOnline'
import MyStayWell from './components/projects/MyStayWell'
import GenericError from './components/GenericError'
import Whs from './components/projects/WHS'
import CountdownSolver from './components/projects/CountdownSolver'
import WordFinder from './components/projects/WordFinder'
import Flashcards from './components/projects/Flashcards'
import ProjectOmega from './components/projects/ProjectOmega'
import DotnetReactSandbox from './components/projects/DotnetReactSandbox'
import DotnetReactGenerator from './components/projects/DotnetReactGenerator'
import RankedVoting from './components/projects/RankedVoting'
import NodeCliUtils from './components/projects/NodeCliUtils'
import DotnetEnvironmentSettings from './components/projects/EnvironmentSettingsDotnet'
import DotnetDbMigrations from './components/projects/DbMigrationsDotnet'

export default function App() {
    return (
        <LayoutWrapper>
            <Switch>
                <Route exact path='/' component={About} />
                <Route exact path='/projects' component={Projects} />
                {/* <Route path='/blog' component={Blog} /> */}
                <Route path='/resume' component={Resume} />
                <Route path='/contact' component={Contact} />
                <Route path='/projects/whs' component={Whs} />
                <Route path='/projects/staywell-online' component={StayWellOnline} />
                <Route path='/projects/my-staywell' component={MyStayWell} />
                <Route path='/projects/rumor-misc' component={RumorMisc} />
                <Route path='/projects/interior-architects' component={InteriorArchitects} />
                <Route path='/projects/canker-complete' component={CankerComplete} />
                <Route path='/projects/dz-nuts' component={DZNuts} />
                <Route path='/projects/brahma' component={Brahma} />
                <Route path='/projects/krosswood-doors' component={KrosswoodDoors} />
                <Route path='/projects/foodinsurance' component={Foodinsurance} />
                <Route path='/projects/evolutioncard' component={Evolutioncard} />
                <Route path='/projects/lochner' component={Lochner} />
                <Route path='/projects/fatbob' component={FatBobsGarage} />
                <Route path='/projects/silversocket' component={SilverSocket} />
                <Route path='/projects/devautologin' component={DevAutoLogin} />
                <Route path='/projects/media-manager' component={MediaManager} />
                <Route path='/projects/slack-dark-theme' component={SlackDarkTheme}/>
                <Route path='/projects/countdown-solver' component={CountdownSolver}/>
                <Route path='/projects/word-finder' component={WordFinder}/>
                <Route path='/projects/flashcards' component={Flashcards}/>
                <Route path='/projects/project-omega' component={ProjectOmega}/>
                <Route path='/projects/dotnet-react-sandbox' component={DotnetReactSandbox}/>
                <Route path='/projects/dotnet-react-generator' component={DotnetReactGenerator}/>
                <Route path='/projects/ranked-voting' component={RankedVoting}/>
                <Route path='/projects/node-cli-utils' component={NodeCliUtils}/>
                <Route path='/projects/environment-settings-dotnet' component={DotnetEnvironmentSettings}/>
                <Route path='/projects/db-migrations-dotnet' component={DotnetDbMigrations}/>
                <Route path='/sorry' component={GenericError} />
                <Route component={NotFound} />
            </Switch>
        </LayoutWrapper>
    )
}

function LayoutWrapper(props) {
    return (
        <div>
            {props.children}
        </div>
    )
}
