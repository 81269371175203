import React from 'react';
import DefaultLayout from '../DefaultLayout';
import ProjectHeader from './ProjectHeader';
import GoBackToProjects from './GoBackToProjects';

import '../../assets/css/carousel-fix.css';

import img from '../../assets/images/projects/brahma.jpg';
import CenteredImage from '../CenteredImage';
import RumorBlurb from './RumorBlurb';

export default function Brahma(props) {
    return (
        <div>
            <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()} />
        </div>
    );
}

const getHeader = () => {
    return <ProjectHeader title="Project: Brahma Group, Inc" />;
}

const getContent = () => {
    return (
        <>
            <GoBackToProjects />
            <section className="project-wrapper single-col-max-width py-5 px-4 mx-auto">
                <div className="section-row">
                    <CenteredImage src={img} alt="Brahma Group, Inc" />
                    <h3 className="section-title">Project Background</h3>
                    <p>Goal: create application form that dynamically converts result into a PDF document.</p>
                    <p>This 3 step employment application captures the user's information, dynamically generates a PDF document and emails a notification to the administrator.</p>
                    <RumorBlurb />
                </div>
            </section>
        </>
    );
}
