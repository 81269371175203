export const skillData = [
    // Top level languages
    {
        key: 'php',
        name: 'PHP'
    },
    {
        key: 'csharp',
        name: 'C#'
    },
    {
        key: 'java',
        name: 'Java'
    },
    
    // General
    {
        key: 'html',
        name: 'HTML'
    },
    {
        key: 'css',
        name: 'CSS'
    },
    {
        key: 'js',
        name: 'Javascript'
    },
    
    // Databases
    {
        key: 'mysql',
        name: 'MySQL'
    },
    {
        key: 'mssql',
        name: 'MSSQL'
    },
    {
        key: 'oracle',
        name: 'Oracle'
    },
    {
        key: 'sqlite',
        name: 'Sqlite'
    },
    {
        key: 'mongodb',
        name: 'MongoDB'
    },
    {
        key: 'elasticsearch',
        name: 'Elasticsearch'
    },
    {
        key: 'postgres',
        name: 'PostgreSQL'
    },
    
    // Misc
    {
        key: 'magento',
        name: 'Magento'
    },
    {
        key: 'dropwizard',
        name: 'Dropwizard'
    },
    {
        key: 'microservices',
        name: 'Microservices'
    },
    {
        key: 'node-express',
        name: 'Node Express'
    },
    {
        key: 'react',
        name: 'React'
    },
    {
        key: 'react-native',
        name: 'React Native'
    },
    {
        key: 'aws',
        name: 'AWS'
    },
    {
        key: 'ios',
        name: 'iOS'
    },
    {
        key: 'android',
        name: 'Android'
    },
    {
        key: 'tfs',
        name: 'TFS'
    },
    {
        key: 'git',
        name: 'Git'
    },
    {
        key: 'aspnet-webforms',
        name: 'ASP.NET WebForms'
    },
    {
        key: 'aspnet-mvc',
        name: 'ASP.NET MVC'
    },
    {
        key: 'jquery',
        name: 'jQuery'
    },
    {
        key: 'knockout',
        name: 'KnockoutJS'
    },
    {
        key: 'sitecore',
        name: 'Sitecore CMS'
    },
    {
        key: 'zencart',
        name: 'ZenCart'
    },
    {
        key: 'pdf',
        name: 'PDF'
    },
    {
        key: 'excel',
        name: 'Excel'
    },
    {
        key: 'gsap',
        name: 'GSAP'
    },
    {
        key: 'chrome',
        name: 'Chrome Extension'
    },
    {
        key: 'shell',
        name: 'Shell'
    },
    {
        key: 'dotnet-core',
        name: '.NET Core'
    },
    {
        key: 'saml',
        name: 'SAML'
    },
    {
        key: 'wordpress',
        name: 'WordPress'
    },
    {
        key: 'junit',
        name: 'JUnit'
    },
    {
        key: 'mstest',
        name: 'MSTest'
    },
    {
        key: 'jest',
        name: 'Jest'
    },
    {
        key: 'enzyme',
        name: 'Enzyme'
    },
    {
        key: 'k6',
        name: 'k6'
    },
    {
        key: 'architecture',
        name: 'Architecture'
    },
    {
        key: 'typescript',
        name: 'Typescript'
    },
    {
        key: 'docker',
        name: 'Docker'
    },
    {
        key: 'kubernetes',
        name: 'Kubernetes'
    },
    {
        key: 'markdown',
        name: 'Markdown'
    },
    {
        key: 'cra',
        name: 'Create React App'
    },
    {
        key: 'pwa',
        name: 'Progressive Web App'
    },
    {
        key: 'vite',
        name: 'Vite'
    },
    {
        key: 'mui',
        name: 'MUI'
    },
    {
        key: 'node',
        name: 'NodeJS'
    },
    {
        key: 'cli',
        name: 'CLI'
    },
    {
        key: 'db-migrations',
        name: 'DB Migrations'
    },
    {
        key: 'npm',
        name: 'NPM'
    }
]