import React from 'react';
import DefaultLayout from '../DefaultLayout';
import ProjectHeader from './ProjectHeader';
import GoBackToProjects from './GoBackToProjects';

import '../../assets/css/carousel-fix.css';

import img from '../../assets/images/projects/TestUserAutoLoginDemo.gif';
import CenteredImage from '../CenteredImage';
import StyledParagraphListLabel from '../StyledParagraphListLabel';
import StyledUl from '../StyledUl';
import StyledLi from '../StyledLi';
import StyledSectionTitle from '../StyledSectionTitle';
import StyledProjectSection from '../StyledProjectSection';

export default function DevAutoLogin(props) {
  return (
    <div>
      <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()}/>
    </div>
  );
}

const getHeader = () => {
  return <ProjectHeader title="Project: Chrome Auto Login Extension"/>;
}

const getContent = () => {
  return (
    <>
      <GoBackToProjects/>
      <StyledProjectSection>
        <CenteredImage src={img} alt="Chrome Auto Login Extension"/>
        <StyledSectionTitle>Project Background</StyledSectionTitle>
        <StyledParagraphListLabel>Goals:</StyledParagraphListLabel>
        <StyledUl>
          <StyledLi>Learn how to create chrome extensions</StyledLi>
          <StyledLi>Create a tool that keeps track of test users for local development that can automatically log a user in with one click</StyledLi>
        </StyledUl>
        <p>I wanted a tool to help me keep track of test users and allow me to login with one click. I also wanted to learn how to create chrome extensions, so
          this was a good opportunity to get started on that. This tool is still a work in progress, but you can check it out on the <a
            href="https://chrome.google.com/webstore/detail/test-user-auto-login/blpplahglbcgdphfkhnlmfhglpgnjlhl">chrome extension store</a> or the code is
          over on github: <a href="https://github.com/mikey-t/chrome-devautologin">https://github.com/mikey-t/chrome-devautologin</a></p>
      </StyledProjectSection>
    </>
  );
}
