import React from 'react'
import DefaultLayout from '../DefaultLayout'
import ProjectHeader from './ProjectHeader'
import GoBackToProjects from './GoBackToProjects'

import img from '../../assets/images/projects/node-cli-utils.png'
import CenteredImage from '../CenteredImage'

export default function NodeCliUtils(props) {
  return (
    <div>
      <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()}/>
    </div>
  )
}

const getHeader = () => {
  return <ProjectHeader title="Project: Node CLI Utils"/>
}

const getContent = () => {
  return (
    <>
      <GoBackToProjects/>
      <section className="project-wrapper single-col-max-width py-5 px-4 mx-auto">
        <div className="section-row">
          <CenteredImage src={img} alt="Word Finder"/>
          <h3 className="section-title">Project Background</h3>
          <p>Over the last few years I have begun adopting a pattern of using package.json commands and gulp tasks to automate many aspects of each of my projects. Whenever
          I've found myself using the same task in multiple projects I've added the helper method to this NPM package for re-use. I intend to re-write this in typescript with
          unit tests "fairly soon" (&#8482;). You can check out an example of this being used in my <a href="https://github.com/mikey-t/dotnet-react-sandbox">dotnet-react-sandbox</a> project (check out the root package.json and gulpfile.js files).</p>
          <p>I've also been adding commands related to my <a href="https://github.com/mikey-t/db-migrations-dotnet">db-migrations-dotnet</a> package. Normally I would admit it's a bad idea to put unrelated things into the same
          package, but in this case my justification is that this package is not bundled or deployed with the project that uses it - it's only for development and doesn't contribute to release package bloat.</p>
          <p>Check it out here: <a href="https://github.com/mikey-t/node-cli-utils">node-cli-utils</a>.</p>
        </div>
      </section>
    </>
  )
}
