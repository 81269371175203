import React, { useEffect } from 'react';
import DefaultLayout from './DefaultLayout';
import ProjectCard from './ProjectCard';
import initIsotope from '../utils/initIsotope';
import { projectData } from '../projectData';

import imgStaywellOnline from '../assets/images/projects/swol.jpg';
import imgMyStaywell from '../assets/images/projects/mystaywell.png';
import imgRumor from '../assets/images/projects/rumor.jpg';
import imgIa from '../assets/images/projects/ia_intranet.jpg';
import imgCanker from '../assets/images/projects/canker.jpg';
import imgDzNuts from '../assets/images/projects/dznuts.jpg';
import imgBrahma from '../assets/images/projects/brahma.jpg';
import imgKrosswood from '../assets/images/projects/krosswood.jpg';
import imgFoodinsurance from '../assets/images/projects/foodinsurance.jpg';
import imgEvolutioncard from '../assets/images/projects/evolutioncard.jpg';
import imgLochner from '../assets/images/projects/lochner.jpg';
import imgFatbob from '../assets/images/projects/fatbob.jpg';
import imgSilverSocket from '../assets/images/projects/silversocket.jpg';
import imgDevAutoLogin from '../assets/images/projects/devautologin.jpg';
import imgMediaManager from '../assets/images/projects/mediamanager.jpg';
import imgSlackDark from '../assets/images/projects/slack-dark.png';
import imgCountdownSolver from '../assets/images/projects/countdown-solver.png';
import imgWordFinder from '../assets/images/projects/word-finder.png';
import imgWhs from '../assets/images/projects/whs-betteryourself.png';
import imgFlashcards from '../assets/images/projects/flashcards.png';
import imgProjectOmega from '../assets/images/projects/project-omega.png';
import imgDotnetReactSandbox from '../assets/images/projects/dotnet-react-sandbox.png';
import imgDotnetReactGenerator from '../assets/images/projects/dotnet-react-generator.png';
import imgRankedVoting from '../assets/images/projects/ranked-voting.png';
import imgNodeCliUtils from '../assets/images/projects/node-cli-utils.png';
import imgEnvironmentSettingsDotnet from '../assets/images/projects/environment-settings-dotnet.png';
import imgDbMigrationsDotnet from '../assets/images/projects/db-migrations-dotnet.png';

import svgStayWell from '../assets/images/svg/StayWell.svg';
import svgRumor from '../assets/images/svg/Rumor.svg';
import svgInteriorArchitects from '../assets/images/svg/InteriorArchitects.svg';
import svgCankerComplete from '../assets/images/svg/CankerComplete.svg';
import svgDznuts from '../assets/images/svg/dznuts.svg';
import svgBrahma from '../assets/images/svg/Brahma.svg';
import svgKrosswood from '../assets/images/svg/Krosswood.svg';
import svgFoodInsurance from '../assets/images/svg/FoodInsurance.svg';
import svgEvolutioncard from '../assets/images/svg/Evolutioncard.svg';
import svgLochner from '../assets/images/svg/Lochner.svg';
import svgFatBobsGarage from '../assets/images/svg/FatBobsGarage.svg';
import svgSilverSocket from '../assets/images/svg/SilverSocket.svg';
import svgChromeAutoLogin from '../assets/images/svg/ChromeAutoLogin.svg';
import svgMediaManager from '../assets/images/svg/MediaManager.svg';
import svgSlackDarkTheme from '../assets/images/svg/SlackDarkTheme.svg';
import svgCountdownSolver from '../assets/images/svg/CountdownSolver.svg';
import svgWordFinder from '../assets/images/svg/WordFinder.svg';
import svgWhs from '../assets/images/svg/WHS.svg'
import svgFlashcards from '../assets/images/svg/Flashcards.svg'
import svgProjectOmega from '../assets/images/svg/ProjectOmega.svg'
import svgDotnetReactSandbox from '../assets/images/svg/DotnetReactSandbox.svg'
import svgDotnetReactGenerator from '../assets/images/svg/DotnetReactGenerator.svg'
import svgRankedVoting from '../assets/images/svg/RankedVoting.svg'
import svgNodeCliUtils from '../assets/images/svg/NodeCliUtils.svg'
import svgEnvironmentSettingsDotnet from '../assets/images/svg/DotnetEnvironmentSettings.svg'
import svgDbMigrationsDotnet from '../assets/images/svg/DotnetDbMigrations.svg'

import { skillData } from '../skillData'

export default function Projects(props) {
    useEffect(() => {
        initIsotope();
    });

    return (
        <div>
            <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()} />
        </div>
    );
}

const productDataForKey = (key) => {
    return projectData.find(pd => pd.projectKey === key);
}

const getHeader = () => {
    return (
        <div className="header-intro theme-bg-primary text-white py-5">
            <div className="container">
                <h2 className="page-heading mb-3">Projects</h2>
                <div className="page-heading-tagline">Examples of some projects I've worked on</div>
            </div>
        </div>
    );
}

const getContent = () => {
    return (
        <section className="section pt-5">
            <div className="container">
                <div className="text-center">
                    <ul id="filters" className="filters mb-5 mx-auto pl-0">
                        <li key="all" className="type active" data-filter="*">All</li>
                        {skillData.map((s, i) => <li key={i} className="type" data-filter={`.${s.key}`}>{s.name}</li>)}
                    </ul>
                </div>

                <div className="project-cards row mb-5 isotope">
                    <ProjectCard projectData={productDataForKey('whs')} cardImg={imgWhs} svgImg={svgWhs} />
                    <ProjectCard projectData={productDataForKey('my-staywell')} cardImg={imgMyStaywell} svgImg={svgStayWell} />
                    <ProjectCard projectData={productDataForKey('staywell-online')} cardImg={imgStaywellOnline} svgImg={svgStayWell} />
                    <ProjectCard projectData={productDataForKey('word-finder')} cardImg={imgWordFinder} svgImg={svgWordFinder} />
                    <ProjectCard projectData={productDataForKey('flashcards')} cardImg={imgFlashcards} svgImg={svgFlashcards} />
                    <ProjectCard projectData={productDataForKey('project-omega')} cardImg={imgProjectOmega} svgImg={svgProjectOmega} />
                    <ProjectCard projectData={productDataForKey('dotnet-react-sandbox')} cardImg={imgDotnetReactSandbox} svgImg={svgDotnetReactSandbox} />
                    <ProjectCard projectData={productDataForKey('dotnet-react-generator')} cardImg={imgDotnetReactGenerator} svgImg={svgDotnetReactGenerator} />
                    <ProjectCard projectData={productDataForKey('ranked-voting')} cardImg={imgRankedVoting} svgImg={svgRankedVoting} />
                    <ProjectCard projectData={productDataForKey('node-cli-utils')} cardImg={imgNodeCliUtils} svgImg={svgNodeCliUtils} />
                    <ProjectCard projectData={productDataForKey('environment-settings-dotnet')} cardImg={imgEnvironmentSettingsDotnet} svgImg={svgEnvironmentSettingsDotnet} />
                    <ProjectCard projectData={productDataForKey('db-migrations-dotnet')} cardImg={imgDbMigrationsDotnet} svgImg={svgDbMigrationsDotnet} />
                    <ProjectCard projectData={productDataForKey('rumor-misc')} cardImg={imgRumor} svgImg={svgRumor} />
                    <ProjectCard projectData={productDataForKey('interior-architects')} cardImg={imgIa} svgImg={svgInteriorArchitects} />
                    <ProjectCard projectData={productDataForKey('canker-complete')} cardImg={imgCanker} svgImg={svgCankerComplete} />
                    <ProjectCard projectData={productDataForKey('dz-nuts')} cardImg={imgDzNuts} svgImg={svgDznuts} />
                    <ProjectCard projectData={productDataForKey('brahma')} cardImg={imgBrahma} svgImg={svgBrahma} />
                    <ProjectCard projectData={productDataForKey('krosswood-doors')} cardImg={imgKrosswood} svgImg={svgKrosswood} />
                    <ProjectCard projectData={productDataForKey('foodinsurance')} cardImg={imgFoodinsurance} svgImg={svgFoodInsurance} />
                    <ProjectCard projectData={productDataForKey('evolutioncard')} cardImg={imgEvolutioncard} svgImg={svgEvolutioncard} />
                    <ProjectCard projectData={productDataForKey('lochner')} cardImg={imgLochner} svgImg={svgLochner} />
                    <ProjectCard projectData={productDataForKey('fatbob')} cardImg={imgFatbob} svgImg={svgFatBobsGarage} />
                    <ProjectCard projectData={productDataForKey('silversocket')} cardImg={imgSilverSocket} svgImg={svgSilverSocket} />
                    <ProjectCard projectData={productDataForKey('devautologin')} cardImg={imgDevAutoLogin} svgImg={svgChromeAutoLogin} />
                    <ProjectCard projectData={productDataForKey('media-manager')} cardImg={imgMediaManager} svgImg={svgMediaManager} />
                    <ProjectCard projectData={productDataForKey('slack-dark-theme')} cardImg={imgSlackDark} svgImg={svgSlackDarkTheme} />
                    <ProjectCard projectData={productDataForKey('countdown-solver')} cardImg={imgCountdownSolver} svgImg={svgCountdownSolver} />
                </div>

            </div>
        </section>
    );
}
