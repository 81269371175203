import React from 'react';
import profileImage from '../assets/images/MikeAndHolly300x225.png';
import DefaultLayout from './DefaultLayout';
import { ReactComponent as FrontEndIcon } from '../assets/images/frontend-icon.svg';
import { ReactComponent as BackEndIcon } from '../assets/images/backend-icon.svg';
import { ReactComponent as OtherSkillsIcon } from '../assets/images/other-skills-icon.svg';
import { Link } from 'react-router-dom';

export default function About(props) {
    return (
        <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()} />
    );
}

const getHeader = () => {
    return (
        <div className="header-intro theme-bg-primary text-white py-5">
            <div className="container">
                <div className="profile-teaser media flex-column flex-md-row">
                    <img className="profile-image mb-3 mb-md-0 mr-md-5 ml-md-0 rounded mx-auto" src={profileImage} alt="Mike Thompson" />
                    <div className="media-body text-center text-md-left">
                        <div className="lead">Hello, my name is</div>
                        <h2 className="mt-0 display-4 font-weight-bold">Mike Thompson</h2>
                        {/* <div className="bio mb-3">I'm a full-stack software engineer. I write about coding on <strong><Link className="link-on-bg" to="/blog">my blog</Link></strong>. Check out my <strong><Link className="link-on-bg" to="/projects">projects</Link></strong> and <strong><Link className="link-on-bg" to="/resume">resume</Link></strong>.</div> */}
                        <div className="bio mb-3">I'm a full-stack software engineer. Check out my <strong><Link className="link-on-bg" to="/projects">projects</Link></strong> and <strong><Link className="link-on-bg" to="/resume">resume</Link></strong>.</div>
                        <Link className="theme-btn-on-bg btn font-weight-bold theme-btn-cta" to="/contact">Hire Me</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

const getContent = () => {
    return (
        <section className="skills-section section py-5">
            <div className="container">
                <h3 className="section-title font-weight-bold text-center mb-3">Skills Overview</h3>
                <div className="section-intro mx-auto text-center mb-5 text-secondary">I have more than 10 years' experience building rich web applications. Below is a quick overview of my main technical skills and tools I use. Want to find out more about my experience? <Link to="/resume">Check out my online resume</Link>.</div>

                <div className="skills-blocks mx-auto pt-5">
                    <div className="row">
                        <div className="skills-block col-12 col-lg-4 mb-5 mb-3 mb-lg-0">
                            <div className="skills-block-inner bg-white shadow-sm py-4 px-5 position-relative">
                                <h4 className="skills-cat text-center mb-3 mt-5">Frontend</h4>
                                <div className="skills-icon-holder position-absolute d-inline-block rounded-circle text-center">
                                    <FrontEndIcon className="skills-icon" />
                                    {/* <img className="skills-icon" src="assets/images/frontend-icon.svg"> */}
                                </div>
                                <ul className="skills-list list-unstyled text-secondary">
                                    {getCheckmarkLi('Javascript')}
                                    {getCheckmarkLi('Typescript')}
                                    {getCheckmarkLi('HTML')}
                                    {getCheckmarkLi('CSS')}
                                    {getCheckmarkLi('React')}
                                    {getCheckmarkLi('Node.js')}
                                    {getCheckmarkLi('jQuery')}
                                </ul>
                            </div>
                        </div>

                        <div className="skills-block col-12 col-lg-4 mb-5 mb-3 mb-lg-0">
                            <div className="skills-block-inner bg-white shadow-sm py-4 px-5 position-relative">
                                <h4 className="skills-cat text-center mb-3 mt-5">Backend</h4>
                                <div className="skills-icon-holder position-absolute d-inline-block rounded-circle text-center">
                                    <BackEndIcon className="skills-icon" />
                                    {/* <img className="skills-icon" src="assets/images/backend-icon.svg"> */}
                                </div>
                                <ul className="skills-list list-unstyled text-secondary">
                                    {getCheckmarkLi('C#')}
                                    {getCheckmarkLi('Java')}
                                    {getCheckmarkLi('PHP')}
                                    {getCheckmarkLi('.NET/MVC/Core/WebAPI')}
                                    {getCheckmarkLi('MySQL/MSSQL')}
                                    {getCheckmarkLi('Sqlite')}
                                    {getCheckmarkLi('MongoDB/ElasticSearch')}
                                </ul>
                            </div>
                        </div>

                        <div className="skills-block col-12 col-lg-4">
                            <div className="skills-block-inner bg-white shadow-sm py-4 px-5 position-relative">
                                <h4 className="skills-cat text-center mb-3 mt-5">Others</h4>
                                <div className="skills-icon-holder position-absolute d-inline-block rounded-circle text-center">
                                    <OtherSkillsIcon className="skills-icon" />
                                    {/* <img className="skills-icon" src="assets/images/other-skills-icon.svg"> */}
                                </div>
                                <ul className="skills-list list-unstyled text-secondary">
                                    {getCheckmarkLi('Visual Studio')}
                                    {getCheckmarkLi('VSCode')}
                                    {getCheckmarkLi('IntelliJ')}
                                    {getCheckmarkLi('MSTest/JUnit/Jest/Enzyme')}
                                    {getCheckmarkLi('Git/TFS')}
                                    {getCheckmarkLi('SAML')}
                                    {getCheckmarkLi('Linux/Windows/Mac')}
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    );
}

const getCheckmarkLi = (text) => {
    return (
        <li className="mb-2"><i className="fas fa-check mr-2 text-primary"></i>{text}</li>
    );
}