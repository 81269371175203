import React from 'react';
import DefaultLayout from '../DefaultLayout';
import ProjectHeader from './ProjectHeader';
import GoBackToProjects from './GoBackToProjects';

import StayWellBackground from './StayWellBackground';
import StyledSectionTitle from '../StyledSectionTitle';

export default function MyStayWell(props) {
  return (
    <div>
      <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()}/>
    </div>
  );
}

const getHeader = () => {
  return <ProjectHeader title="Project: WebMD Health Services"/>;
}

const getContent = () => {
  return (
    <>
      <GoBackToProjects/>
      <section className="project-wrapper single-col-max-width py-5 px-4 mx-auto">
        <div className="section-row">
          <StayWellBackground/>
          <StyledSectionTitle>WebMD Health Services</StyledSectionTitle>
          <p>In 2020 WebMD Health Services acquired StayWell and is in the process of migrating customers to the WebMD One platform.</p>
          <p>I spent 2019-2020 on the StayWell architecture team and transitioned to the WHS architecture team in 2021. I was officially promoted to the title
            Architect in July of 2021.</p>
          <StyledSectionTitle>AWS to On-Prem Migration</StyledSectionTitle>
          <p>I assisted in planning and execution of migrating our MyStayWell platform from AWS to WHS's on-prem data centers. This invovled working with other
            engineers to create/update documentation, audit AWS service usages within our application and troubleshooting issues after the migration was
            completed.</p>
          <p>One interesting problem we had was that the new on-prem database did not have the same horsepower as the AWS hosted instance, so our application
            suffered severe performance issues. I used NewRelic to identify application changes we could make to bring the system back to normal operational
            performance, then worked with another developer to design the solution implementation. As part of this process I documented before and after
            pformance metrics in order to show exactly how much of an improvement we made, as well as to help justify the company's continued use of
            NewRelic.</p>
          <StyledSectionTitle>Application Performance Investigation</StyledSectionTitle>
          <p>We had a microservice mysteriously hitting a performance wall and opening http circuits. I investigated the issue by reproducing locally using k6
            tests to apply enough load to trigger the scenario. I found that our use of sync wrapped async http calls between a proxy and the service in
            question was causing requests to stack up and
            timeout. We implemented a temporary solution of adjusting max connections and min threads before implementing a true async fix. </p>
          <StyledSectionTitle>K6 Performance/Load Testing Utilities</StyledSectionTitle>
          <p>While investigating the performance issue mentioned above, I had the opportunity to learn more about <a href="https://k6.io/">k6</a>. While it's a
            very powerful tool, there is some overhead involved in first-time use and I wanted other developers to be able to easily setup k6 and write tests
            and be able to see
            results in <a href="https://grafana.com/">grafana</a>. I created a docker project that wraps influxdb, grafana and openapigenerator so that
            developers can rappidly generate new k6 tests and push results to a local grafana instance running in a docker container. Then I created an npm
            package to encapsulate shared k6 utility methods and a run command that automatically appends all the necessary k6 CLI params to push results to the
            local running influxdb/grafana instance.</p>
          <StyledSectionTitle>MyStayWell Third-Party API Disconnection</StyledSectionTitle>
          <p>After combining one of MyStayWell's third-party API accounts with WHS's, we needed to disconnect a very large number of MyStayWell users so that they could
            re-connect on the new platform. I wrote a multi-threaded script to rapidly disconnect all the users.</p>
          <StyledSectionTitle>Token Based Authentication</StyledSectionTitle>
          <p>Our primary application's auth mechanisms previously revolved around a .NET session, but this had several problems:</p>
          <ul>
            <li>Inability to consistently protect service endpoints in microservices</li>
            <li>Poor performance caused by continually reaching back from microservices to the monolith application for common user info</li>
            <li>No easy or consistent way to expose API's for customer consumption</li>
            <li>Inability to have granulary service endpoint access</li>
            <li>Audit logging lacked the ability to trace specific internal production use cases</li>
          </ul>
          <p>We were able to resurect an old project to implement token based authentication and get it over the finish line. We now have solutions to all the above
          problems and by doing so have opened up a broad range of possibilities for our infrustructure and platform as a whole. The system security was already good,
          but is now even better with more granulary access control and audit logging.</p>
        </div>
      </section>
    </>
  );
}
