import React from 'react';
import DefaultLayout from '../DefaultLayout';
import ProjectHeader from './ProjectHeader';
import GoBackToProjects from './GoBackToProjects';

import '../../assets/css/carousel-fix.css';

import img from '../../assets/images/projects/fatbob.jpg';
import CenteredImage from '../CenteredImage';

export default function FatBobsGarage(props) {
    return (
        <div>
            <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()} />
        </div>
    );
}

const getHeader = () => {
    return <ProjectHeader title="Project: Fat Bob's Garage" />;
}

const getContent = () => {
    return (
        <>
            <GoBackToProjects />
            <section className="project-wrapper single-col-max-width py-5 px-4 mx-auto">
                <div className="section-row">
                    <CenteredImage src={img} alt="Fat Bob's Garage" />
                    <h3 className="section-title">Project Background</h3>
                    <p>Goal: create a front-end website for a shopping cart hosted at Nexternal.</p>
                    <p>Built front-end homepage and search utility and integrated it into a third party shopping cart application on a separate website.</p>
                </div>
            </section>
        </>
    );
}
