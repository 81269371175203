import React from 'react'
import DefaultLayout from '../DefaultLayout'
import ProjectHeader from './ProjectHeader'
import GoBackToProjects from './GoBackToProjects'

import img from '../../assets/images/projects/word-finder.png'
import CenteredImage from '../CenteredImage'

export default function WordFinder(props) {
  return (
    <div>
      <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()}/>
    </div>
  )
}

const getHeader = () => {
  return <ProjectHeader title="Project: Word Finder"/>
}

const getContent = () => {
  return (
    <>
      <GoBackToProjects/>
      <section className="project-wrapper single-col-max-width py-5 px-4 mx-auto">
        <div className="section-row">
          <CenteredImage src={img} alt="Word Finder"/>
          <h3 className="section-title">Project Background</h3>
          <p>I previously created a word finder with my <a href="https://countdown.mikeyt.net">countdown-solver</a> but I was no longer happy with a number of things:</p>
          <ul>
            <li>Outdated versions of .NET and React</li>
            <li>Use of Sqlite (great tool, but not the best choice for what I would like to do with this project)</li>
            <li>Old version of CRA and sub-par implementation of a PWA</li>
            <li>Word solver lacks filters that would make it useful for other games</li>
            <li>Slow build times</li>
            <li>Not integrated into my latest tools and processes</li>
          </ul>
          <p>By re-implementing the solution I had a chance to try out <a href="https://vitejs.dev/">Vite</a> for the front-end tooling, upgraded all the tech,
            and added some helpful filters for use with other games. I also took the opportunity to increase my Typescript and Material UI skills.</p>
          <p>Check it out here: <a href="https://words.mikeyt.net">https://words.mikeyt.net</a>.</p>
        </div>
      </section>
    </>
  )
}
