export const projectData = [
  {
    projectKey: 'whs',
    techUsed: ['html', 'css', 'js', 'node-express', 'react', 'microservices', 'git', 'saml', 'architecture', 'aspnet-mvc', 'dotnet-core', 'docker', 'k6', 'typescript', 'docker'],
    cardImage: {
      src: 'swol.jpg',
    },
    cardTitle: 'WebMD Health Services',
    cardOverlayTitle: 'WebMD Health Services',
    cardOverlayText: 'Enterprise React, ASP.NET MVC/Core, Microservices application in the healthcare industry. Full-time position.'
  },
  {
    projectKey: 'my-staywell',
    techUsed: ['java', 'dropwizard', 'html', 'css', 'js', 'node-express', 'react', 'microservices', 'aws', 'ios', 'android', 'react-native', 'git', 'saml', 'junit', 'jest', 'enzyme', 'architecture'],
    cardImage: {
      src: 'swol.jpg',
    },
    cardTitle: 'MyStayWell Platform',
    cardOverlayTitle: 'MyStayWell Platform',
    cardOverlayText: 'Enterprise React, Java, Microservices application in the healthcare industry. Full-time position.'
  },
  {
    projectKey: 'staywell-online',
    techUsed: ['csharp', 'html', 'css', 'js', 'aspnet-webforms', 'aspnet-mvc', 'mssql', 'oracle', 'mongodb', 'elasticsearch', 'sitecore', 'jquery', 'knockout', 'tfs', 'saml', 'mstest'],
    cardImage: {
      src: 'swol.jpg',
    },
    cardTitle: 'StayWell Online',
    cardOverlayTitle: 'StayWell Online',
    cardOverlayText: 'Enterprise .NET, C# ,Oracle, MSSQL, MongoDB, Sitecore application in the healthcare industry. Full-time position.'
  },
  {
    projectKey: 'rumor-misc',
    techUsed: ['php', 'csharp', 'html', 'css', 'js', 'jquery', 'mysql', 'magento', 'zencart', 'wordpress'],
    cardImage: {
      src: 'rumor.jpg',
    },
    cardTitle: 'Rumor Advertising',
    cardOverlayTitle: 'Miscellaneous projects for Rumor Advertising',
    cardOverlayText: 'Misc projects while working full-time for Rumor Advertising'
  },
  {
    projectKey: 'interior-architects',
    techUsed: ['php', 'csharp', 'html', 'css', 'js', 'jquery'],
    cardImage: {
      src: 'ia_intranet.jpg',
    },
    cardTitle: 'Interior Architects',
    cardOverlayTitle: 'Interior Architects projects completed at Rumor Advertising',
    cardOverlayText: 'Back-end content management, job application form and corporate store'
  },
  {
    projectKey: 'canker-complete',
    techUsed: ['php', 'html', 'css', 'js', 'magento'],
    cardImage: {
      src: 'canker.jpg',
    },
    cardTitle: 'Canker Complete',
    cardOverlayTitle: 'Canker Complete',
    cardOverlayText: 'Magento e-commerce implementation'
  },
  {
    projectKey: 'dz-nuts',
    techUsed: ['php', 'html', 'css', 'js', 'zencart'],
    cardImage: {
      src: 'dznuts.jpg',
    },
    cardTitle: 'dznuts',
    cardOverlayTitle: 'dznuts',
    cardOverlayText: 'ZenCart e-commerce implementation'
  },
  {
    projectKey: 'brahma',
    techUsed: ['php', 'html', 'css', 'js', 'pdf'],
    cardImage: {
      src: 'brahma.jpg',
    },
    cardTitle: 'Brahma Group, Inc',
    cardOverlayTitle: 'Brahma Group, Inc',
    cardOverlayText: 'Job application form and dynamic PDF generation'
  },
  {
    projectKey: 'krosswood-doors',
    techUsed: ['php', 'html', 'css', 'js', 'mysql', 'excel'],
    cardImage: {
      src: 'krosswood.jpg',
    },
    cardTitle: 'Krosswood Doors',
    cardOverlayTitle: 'Krosswood Doors',
    cardOverlayText: 'Custom CRM, content management and dynamic excel generation'
  },
  {
    projectKey: 'foodinsurance',
    techUsed: ['php', 'mysql', 'html', 'css', 'js', 'jquery'],
    cardImage: {
      src: 'foodinsurance.jpg',
    },
    cardTitle: 'Foodinsurance',
    cardOverlayTitle: 'Foodinsurance',
    cardOverlayText: 'Magento e-commerce implementation'
  },
  {
    projectKey: 'evolutioncard',
    techUsed: ['php', 'mysql', 'html', 'css', 'js'],
    cardImage: {
      src: 'evolutioncard.jpg',
    },
    cardTitle: 'Evolutioncard',
    cardOverlayTitle: 'Evolutioncard',
    cardOverlayText: 'Misc PHP/MySQL applications to support call center and internal product management'
  },
  {
    projectKey: 'lochner',
    techUsed: ['csharp', 'mssql', 'html', 'css', 'js', 'jqyery'],
    cardImage: {
      src: 'lochner.jpg',
    },
    cardTitle: 'Lochner',
    cardOverlayTitle: 'Lochner',
    cardOverlayText: 'C# MSSQL company intranet and information static site. Full-time position.'
  },
  {
    projectKey: 'fatbob',
    techUsed: ['csharp', 'aspnet-webforms', 'html', 'css', 'js', 'jquery'],
    cardImage: {
      src: 'fatbob.jpg',
    },
    cardTitle: 'Fat Bob\'s Garage',
    cardOverlayTitle: 'Fat Bob\'s Garage',
    cardOverlayText: 'C# ASP.NET store front with production selection user interface'
  },
  {
    projectKey: 'silversocket',
    techUsed: ['csharp', 'html', 'css', 'js', 'jquery', 'gsap'],
    cardImage: {
      src: 'silversocket.jpg',
    },
    cardTitle: 'SilverSocket',
    cardOverlayTitle: 'SilverSocket',
    cardOverlayText: 'C# ASP.NET website for my LLC. Used this website project to try out GSAP (GreenSock Animation Platform).'
  },
  {
    projectKey: 'devautologin',
    techUsed: ['html', 'css', 'js', 'jquery', 'chrome'],
    cardImage: {
      src: 'devautologin.jpg',
    },
    cardTitle: 'Chrome Auto Login Extension',
    cardOverlayTitle: 'Chrome Auto Login Extension',
    cardOverlayText: 'Chrome extension to keep track of test users and automatically log me in with test users to sites I am working on locally'
  },
  {
    projectKey: 'media-manager',
    techUsed: ['csharp', 'mssql', 'html', 'css', 'js', 'jquery', 'chrome'],
    cardImage: {
      src: 'mediamanager.jpg',
    },
    cardTitle: 'Media Manager',
    cardOverlayTitle: 'Media Manager',
    cardOverlayText: 'C# MVC site with an accompanying chrome extension to save and keep track of funny gifs'
  },
  {
    projectKey: 'slack-dark-theme',
    techUsed: ['shell'],
    cardImage: {
      src: 'Slack-dark-theme.jpg',
    },
    cardTitle: 'Slack Dark Theme',
    cardOverlayTitle: 'Slack Dark Theme',
    cardOverlayText: 'Shell script to mod Slack and replace theme with a custom dark version'
  },
  {
    projectKey: 'countdown-solver',
    techUsed: ['csharp', 'js', 'sqlite', 'dotnet-core', 'react'],
    cardImage: {
      src: 'countdown-solver.png',
    },
    cardTitle: 'Countdown Solver',
    cardOverlayTitle: 'Countdown Solver',
    cardOverlayText: 'C#/React/Sqlite project to experiment with an algorithm to efficiently find words from a set of letters'
  },
  {
    projectKey: 'word-finder',
    techUsed: ['csharp', 'js', 'ts', 'react', 'vite', 'postgres'],
    cardImage: {
      src: 'word-finder.png',
    },
    cardTitle: 'Word Finder',
    cardOverlayTitle: 'Word Finder',
    cardOverlayText: 'C#/React/Vite project to upgrade the UI with filters for countdown-solver while also trying out Vite'
  },
  {
    projectKey: 'flashcards',
    techUsed: ['csharp', 'js', 'typescript', 'react', 'postgres', 'cra', 'pwa', 'markdown', 'mui'],
    cardImage: {
      src: 'flashcards.png'
    },
    cardTitle: 'Flashcards',
    cardOverlayTitle: 'Flashcards',
    cardOverlayText: 'C#/React/PWA project to create a flashcards educational progressive web app'
  },
  {
    projectKey: 'project-omega',
    techUsed: ['csharp', 'js', 'react', 'microservices', 'kubernetes', 'docker'],
    cardImage: {
      src: 'project-omega.png'
    },
    cardTitle: 'Project Omega',
    cardOverlayTitle: 'Project Omega',
    cardOverlayText: 'Proof of concept for a true monolith microservices hybrid with docker and kubernetes demos'
  },
  {
    projectKey: 'dotnet-react-sandbox',
    techUsed: ['csharp', 'js', 'typescript', 'react', 'docker', 'postgres', 'vite', 'mui'],
    cardImage: {
      src: 'dotnet-react-sandbox.png'
    },
    cardTitle: 'Dotnet React Sandbox',
    cardOverlayTitle: 'Dotnet React Sandbox',
    cardOverlayText: 'C#/React app to use as a staging ground for trying things and as a template for spinning up a new project'
  },
  {
    projectKey: 'dotnet-react-generator',
    techUsed: ['js', 'typescript', 'node', 'cli', 'npm'],
    cardImage: {
      src: 'dotnet-react-generator.png'
    },
    cardTitle: 'Dotnet React Generator',
    cardOverlayTitle: 'Dotnet React Generator',
    cardOverlayText: 'Node CLI utility and NPM package to generate new projects based on the dotnet-react-sandbox project'
  },
  {
    projectKey: 'ranked-voting',
    techUsed: ['js', 'typescript', 'node', 'npm'],
    cardImage: {
      src: 'ranked-voting.png'
    },
    cardTitle: 'Ranked Voting',
    cardOverlayTitle: 'Ranked Voting',
    cardOverlayText: 'Typescript NPM package to facilitate calculating ranked voting election results'
  },
  {
    projectKey: 'node-cli-utils',
    techUsed: ['js', 'node', 'cli', 'npm'],
    cardImage: {
      src: 'node-cli-utils.png'
    },
    cardTitle: 'Node CLI Utils',
    cardOverlayTitle: 'Node CLI Utils',
    cardOverlayText: 'Javascript NPM package to contain miscellaneous automation helper methods I use frequently in other projects'
  },
  {
    projectKey: 'environment-settings-dotnet',
    techUsed: ['csharp'],
    cardImage: {
      src: 'environment-settings-dotnet.png'
    },
    cardTitle: 'Dotnet Environment Settings',
    cardOverlayTitle: 'Dotnet Environment Settings',
    cardOverlayText: 'C# NuGet package with a wrapper for describing and accessing an application environment settings'
  },
  {
    projectKey: 'db-migrations-dotnet',
    techUsed: ['csharp', 'ef', 'db-migrations', 'cli'],
    cardImage: {
      src: 'db-migrations-dotnet.png'
    },
    cardTitle: 'Dotnet DB Migrations',
    cardOverlayTitle: 'Dotnet DB Migrations',
    cardOverlayText: 'C# library and JavaScript automation examples to demonstrate DB migrations in a .NET application without a third party framework'
  },
  {
    projectKey: '',
    techUsed: [],
    cardImage: {
      src: ''
    },
    cardTitle: '',
    cardOverlayTitle: '',
    cardOverlayText: ''
  }
]
