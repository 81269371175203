import React from 'react';

export default function ProjectHeader(props) {
    return (
        <div className="header-intro theme-bg-primary text-white py-5">
            <div className="container position-relative">
                <h2 className="page-heading mb-2">{props.title}</h2>
                {props.tagline && <div className="page-heading-tagline">{props.tagline}</div>}
                {/* <div className="page-heading-logo font-weight-bold position-absolute mt-4 mt-md-0">
                    <img className="client-logo" src="assets/images/logos/logo-2-inverse.svg" />
                </div> */}
            </div>
        </div>
    );
}