import React from 'react';

export default function (props) {
    return (
        <section className="project-wrapper single-col-max-width py-5 px-4 mx-auto">
            <div className="section-row">
                {props.children}
            </div>
        </section>
    );
}