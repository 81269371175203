import React from 'react'
import DefaultLayout from '../DefaultLayout'
import ProjectHeader from './ProjectHeader'
import GoBackToProjects from './GoBackToProjects'

import img from '../../assets/images/projects/dotnet-react-generator.png'
import CenteredImage from '../CenteredImage'

export default function DotnetReactGenerator(props) {
  return (
    <div>
      <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()}/>
    </div>
  )
}

const getHeader = () => {
  return <ProjectHeader title="Project: Dotnet React Generator"/>
}

const getContent = () => {
  return (
    <>
      <GoBackToProjects/>
      <section className="project-wrapper single-col-max-width py-5 px-4 mx-auto">
        <div className="section-row">
          <CenteredImage src={img} alt="Word Finder"/>
          <h3 className="section-title">Project Background</h3>
          <p>This project is an NPM package to bootstrap a new project using <a href="https://github.com/mikey-t/dotnet-react-sandbox">dotnet-react-sandbox</a> as
            a template. There are a number of popular frameworks (like Yeoman) for creating project generators, but I wanted to level up my typescript and
            node CLI skills so I rolled my own. I feel like that was a good choice becase it was low effort and my use case includes some semi non-standard
            actions like generating and installing SSL certificates on multiple platforms.
          </p>
          <p>Check it out here: <a href="https://github.com/mikey-t/dotnet-react-generator">dotnet-react-generator</a>.</p>
        </div>
      </section>
    </>
  )
}
