import React from 'react';
import { skillData } from '../skillData';
import { Link } from 'react-router-dom';

export default function ProjectCard(props) {
    const { projectData, cardImg, svgImg } = props;
    
    let hasTech = (tech) => {
        return projectData.techUsed.includes(tech);
    }

    return (
        <div className={`isotope-item col-12 col-lg-4 ${projectData.techUsed.join(' ')}`}>
            <div className="card rounded-0 border-0 shadow-sm mb-5">
                <div className="card-img-container position-relative">
                    <img className="card-img-top rounded-0" src={cardImg} alt={projectData.cardTitle} />
                    <div className="card-img-overlay overlay-logo text-center">
                        <div className="project-logo">{svgImg && <img className="img-fluid w-50" src={svgImg} alt=""></img>}</div>
                    </div>
                    <Link className="card-img-overlay overlay-content text-left p-lg-4" to={`/projects/${projectData.projectKey}`}>
                        <h5 className="card-title font-weight-bold">{projectData.cardOverlayTitle}</h5>
                        <p className="card-text">{projectData.cardOverlayText}</p>
                    </Link>
                </div>
                <div className="card-body pb-0">
                    <h4 className="card-title text-truncate text-center mb-0"><Link to={`/projects/${projectData.projectKey}`}>{projectData.cardTitle}</Link></h4>
                </div>

                <div className="card-footer border-0 text-center bg-white pb-4">
                    <ul className="list-inline mb-0 mx-auto">
                        {skillData.map((s) => hasTech(s.key) && <SkillPill key={s.key} name={s.name} />)}
                    </ul>
                </div>
            </div>
        </div>
    );
}

const SkillPill = (props) => {
    return (
        <li className="list-inline-item"><span className="badge badge-secondary badge-pill">{props.name}</span></li>
    );
}
