import React from 'react';
import DefaultLayout from './DefaultLayout';

export default function NotFound(props) {
    return <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()} hideFooterInvite />
}

const getHeader = () => {
    return (
        <div className="header-intro theme-bg-primary text-white py-5">
            <div className="container">
                <h2 className="page-heading mb-0">Not Found</h2>
            </div>
        </div>
    );
}

const getContent = () => {
    return (
        <section className="section py-5">
            <div className="container">
                    <h1 className="text-center" style={{fontSize:'10rem'}}>404</h1>
            </div>
        </section>
    );
}
