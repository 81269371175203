import React from 'react';
import DefaultLayout from '../DefaultLayout';
import ProjectHeader from './ProjectHeader';
import GoBackToProjects from './GoBackToProjects';

import '../../assets/css/carousel-fix.css';

import img from '../../assets/images/projects/silversocket.jpg';
import silversocketAnimation from '../../assets/images/projects/silversocket-animation.gif'
import CenteredImage from '../CenteredImage';

export default function SilverSocket(props) {
  return (
    <div>
      <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()}/>
    </div>
  );
}

const getHeader = () => {
  return <ProjectHeader title="Project: SilverSocket"/>;
}

const getContent = () => {
  return (
    <>
      <GoBackToProjects/>
      <section className="project-wrapper single-col-max-width py-5 px-4 mx-auto">
        <div className="section-row">
          <CenteredImage src={img} alt="SilverSocket"/>
          <h3 className="section-title">Project Background</h3>
          <p>SilverSocket is an LLC I started in order to facilitate freelance work. I created the SilverSocket website to provide potential clients a way to
            contact me and also to try out an animation library called <a href="https://greensock.com/">greensock</a> for fun!</p>
          <p>This site is no longer live, but here's an example of one of the animations:</p>
          <img src={silversocketAnimation} alt="SilverSocket project picker animation" />
        </div>
      </section>
    </>
  );
}
