import React from 'react';

export default function () {
  return (
    <>
      <h3 className="section-title">StayWell and WebMD Health Services Background</h3>
      <p>I have worked full-time at StayWell, which has now been acquired by WebMD Health Services, since 2010. When I started the company was called StayWell Interactive. The company restructured and underwent
        ownership changes several times. During the first major restrucuring, the company changed its name to Krames StayWell. When our employer based
        population health management software product moved from StayWell Interactive to MediMedia's sister company StayWell Health Management, I moved with it.
        The companies later merged together to form Krames Staywell. Merck then purchased a majority stake in the company and it was renamed <a
          href="https://www.staywell.com/">StayWell.</a></p>
      <p>StayWell aquired Provata Health in 2018 and transitioned all customers to the new platform. In July 2020 WebMD Health Services acquired StayWell and is
        gradually migrating all customers to their WebMD One platform.</p>
    </>
  );
}