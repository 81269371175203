import React from 'react';
import DefaultLayout from '../DefaultLayout';
import ProjectHeader from './ProjectHeader';
import GoBackToProjects from './GoBackToProjects';

import '../../assets/css/carousel-fix.css';

import img from '../../assets/images/projects/dznuts.jpg';
import CenteredImage from '../CenteredImage';
import RumorBlurb from './RumorBlurb';

export default function DZNuts(props) {
    return (
        <div>
            <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()} />
        </div>
    );
}

const getHeader = () => {
    return <ProjectHeader title="Project: DZ Nuts" />;
}

const getContent = () => {
    return (
        <>
            <GoBackToProjects />
            <section className="project-wrapper single-col-max-width py-5 px-4 mx-auto">
                <div className="section-row">
                    <CenteredImage src={img} alt="Canker Complete" />
                    <h3 className="section-title">Project Background</h3>
                    <p>Goal: customized e-commerce website.</p>
                    <p>Customized and deployed a <a href="https://www.zen-cart.com/">ZenCart</a> implementation to meet the customer's needs. </p>
                    <RumorBlurb />
                </div>
            </section>
        </>
    );
}
