import React from 'react';
import DefaultLayout from '../DefaultLayout';
import ProjectHeader from './ProjectHeader';
import GoBackToProjects from './GoBackToProjects';

import '../../assets/css/carousel-fix.css';

import img from '../../assets/images/projects/krosswood.jpg';
import CenteredImage from '../CenteredImage';

export default function KrosswoodDoors(props) {
    return (
        <div>
            <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()} />
        </div>
    );
}

const getHeader = () => {
    return <ProjectHeader title="Project: Krosswood Doors" />;
}

const getContent = () => {
    return (
        <>
            <GoBackToProjects />
            <section className="project-wrapper single-col-max-width py-5 px-4 mx-auto">
                <div className="section-row">
                    <CenteredImage src={img} alt="Brahma Group, Inc" />
                    <h3 className="section-title">Project Background</h3>
                    <p>Goal:salvage code written by offshore freelance developer and finish developing custom CRM.</p>
                    <p>This site includes many features, including:</p>
                    <ul>
                        <li className="mb-3">Custom role based user authentication</li>
                        <li className="mb-3">Distributor management</li>
                        <li className="mb-3">Sales Rep management</li>
                        <li className="mb-3">Dealer management</li>
                        <li className="mb-3">Report generator</li>
                        <li className="mb-3">Asset management</li>
                        <li className="mb-3">Press releases</li>
                        <li className="mb-3">Sales Tracking</li>
                        <li className="mb-3">Geocoding tool integrated into dealer management</li>
                    </ul>
                    <p>This project was completed while working full-time at Rumor Advertising.</p>
                </div>
            </section>
        </>
    );
}
