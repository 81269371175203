import React from 'react';
import DefaultLayout from '../DefaultLayout';
import ProjectHeader from './ProjectHeader';
import GoBackToProjects from './GoBackToProjects';

import '../../assets/css/carousel-fix.css';

import img from '../../assets/images/projects/evolutioncard.jpg';
import CenteredImage from '../CenteredImage';

export default function Evolutioncard(props) {
  return (
    <div>
      <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()}/>
    </div>
  );
}

const getHeader = () => {
  return <ProjectHeader title="Project (full-time position): Evolutioncard"/>;
}

const getContent = () => {
  return (
    <>
      <GoBackToProjects/>
      <section className="project-wrapper single-col-max-width py-5 px-4 mx-auto">
        <div className="section-row">
          <CenteredImage src={img} alt="Brahma Group, Inc"/>
          <h3 className="section-title">Project Background</h3>
          <p>While working full-time at Evolutioncard I built a number of small PHP web applications to assist the call center. I also helped with maintaining
            the customer facing PHP website.</p>
        </div>
      </section>
    </>
  );
}
