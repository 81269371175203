import React from 'react'
import DefaultLayout from '../DefaultLayout'
import ProjectHeader from './ProjectHeader'
import GoBackToProjects from './GoBackToProjects'

import img from '../../assets/images/projects/dotnet-react-sandbox.png'
import CenteredImage from '../CenteredImage'

export default function DotnetReactSandbox(props) {
  return (
    <div>
      <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()}/>
    </div>
  )
}

const getHeader = () => {
  return <ProjectHeader title="Project: Dotnet React Sandbox"/>
}

const getContent = () => {
  return (
    <>
      <GoBackToProjects/>
      <section className="project-wrapper single-col-max-width py-5 px-4 mx-auto">
        <div className="section-row">
          <CenteredImage src={img} alt="Word Finder"/>
          <h3 className="section-title">Project Background</h3>
          <p>This project is where I try out new stuff, including integrating NPM and NuGet libraries I've created. I'm also using this project to bootstrap other
          new projects via my <a href="https://github.com/mikey-t/dotnet-react-generator">dotnet-react-generator</a> NPM package.</p>
          <p>Check it out here: <a href="https://github.com/mikey-t/dotnet-react-sandbox">dotnet-react-sandbox</a>.</p>
        </div>
      </section>
    </>
  )
}
