import React from 'react';
import DefaultLayout from '../DefaultLayout';
import ProjectHeader from './ProjectHeader';
import GoBackToProjects from './GoBackToProjects';

import '../../assets/css/carousel-fix.css';

import imgIa from '../../assets/images/projects/ia.jpg';
import imgIaIntranet from '../../assets/images/projects/ia_intranet_442.jpg';
import imgIaCorpStore from '../../assets/images/projects/ia_corp_store_442.jpg';

import {ReactComponent as CarouselLeft} from '../../assets/images/carousel-left.svg';
import {ReactComponent as CarouselRight} from '../../assets/images/carousel-right.svg';
import RumorBlurb from './RumorBlurb';

export default function InteriorArchitects(props) {
  return (
    <div>
      <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()}/>
    </div>
  );
}

const getHeader = () => {
  return <ProjectHeader title="Project: Interior Architects"/>;
}

const getContent = () => {
  return (
    <>
      <GoBackToProjects/>
      <section className="project-wrapper single-col-max-width py-5 px-4 mx-auto">
        <div className="section-row">
          <div id="project-carousel" className="theme-carousel carousel slide mb-5" data-ride="carousel" style={{maxWidth: 575, margin: 'auto'}}>
            <ol className="carousel-indicators">
              <li data-target="#project-carousel" data-slide-to="0" className="active"></li>
              <li data-target="#project-carousel" data-slide-to="1"></li>
              <li data-target="#project-carousel" data-slide-to="2"></li>
            </ol>

            <div className="carousel-inner">
              <div className="carousel-item active">
                <img className="d-block w-100" src={imgIa} alt="Interior Architects Screenshot"/>
              </div>
              <div className="carousel-item">
                <img className="d-block w-100" src={imgIaIntranet} alt="Interior Architects Corporate Intranet Screenshot"/>
              </div>
              <div className="carousel-item">
                <img className="d-block w-100" src={imgIaCorpStore} alt="Interior Architects Corporate Store Screenshot"/>
              </div>
            </div>

            <a className="carousel-control-prev" href="#project-carousel" role="button" data-slide="prev">
              <CarouselLeft/>
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#project-carousel" role="button" data-slide="next">
              <CarouselRight/>
              <span className="sr-only">Next</span>
            </a>
          </div>

          <h3 className="section-title">Project Background</h3>
          <p>Goals:</p>
          <ul>
            <li className="mb-3">Create back-end content management and make that content available for the flash application. Create an interface for job
              applicants to upload their resume and cover letter and select which jobs they are interested in.
            </li>
            <li className="mb-3">Build a corporate store on the company intranet that integrates with third party fulfillment</li>
            <li className="mb-3">Build website from Photoshop documents and transfer content of old site to the new corporate intranet site</li>
          </ul>
        </div>

        <p>The back-end interface allows the admin to create job postings, press releases and public speaking events. Content integrated into flash application
          using javascript hooks and a popup page.</p>

        <p>The corporate store application was written in ASP.NET/C# to cater to the client's hosting environment. The site functions as a normal shopping cart
          but submits the information via HTTP request to the fulfillment company's server instead of processing payment information or handling other standard
          ecommerce tasks.</p>

        <p>Also built corporate intranet website using ASP.NET/C# to cater to client's hosting environment. Ported content from old intranet to this new
          site.</p>

        <RumorBlurb/>
      </section>
    </>
  );
}
