import React from 'react';
import DefaultLayout from '../DefaultLayout';
import ProjectHeader from './ProjectHeader';
import GoBackToProjects from './GoBackToProjects';

import '../../assets/css/carousel-fix.css';

import img from '../../assets/images/projects/slack-dark.png';
import CenteredImage from '../CenteredImage';

export default function SlackDarkTheme(props) {
  return (
    <div>
      <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()}/>
    </div>
  );
}

const getHeader = () => {
  return <ProjectHeader title="Project: Slack Dark Theme"/>;
}

const getContent = () => {
  return (
    <>
      <GoBackToProjects/>
      <section className="project-wrapper single-col-max-width py-5 px-4 mx-auto">
        <div className="section-row">
          <CenteredImage src={img} alt="Slack Dark Theme"/>
          <h3 className="section-title">Project Background</h3>
          <p><strong>UPDATE:</strong> the Slack team finally released a built-in dark theme, so this project isn't necessary anymore.</p>
          <p>Project code and instructions can be found on GitHub: <a href="https://github.com/mikey-t/slack-dark-theme">slack-dark-theme</a>.</p>
          <p>The Slack team has been dragging their feet on creating a dark theme for the desktop version of their app so many people in the community found
            ways to override the exisitng theme with their own dark theme via css injected into one of the app's js files. However, whenever Slack pushed
            updates it would overwrite these custom styles, and with version 4 of their app the "easy way" of modding the theme became more complicated.</p>
          <p>To make it easier to reapply the dark theme after Slack updates, I wrote a script that can unpack the app, modify the appropriate file and re-pack
            it without having to take any manual steps. Currently the script works for MacOS and Linux (non-snap version of Slack).</p>
        </div>
      </section>
    </>
  );
}
