import React from 'react';
import DefaultLayout from '../DefaultLayout';
import ProjectHeader from './ProjectHeader';
import GoBackToProjects from './GoBackToProjects';

import '../../assets/css/carousel-fix.css';

import img from '../../assets/images/projects/mediamanager.jpg';
import imgContextMenu from '../../assets/images/projects/mediamanager_contextmenu.jpg';
import imgUpload from '../../assets/images/projects/mediamanager_upload.jpg';
import imgGallery from '../../assets/images/projects/mediamanager_gallery.jpg';
import CenteredImage from '../CenteredImage';

export default function MediaManager(props) {
    return (
        <div>
            <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()} />
        </div>
    );
}

const getHeader = () => {
    return <ProjectHeader title="Project: Media Manager" />;
}

const getContent = () => {
    return (
        <>
            <GoBackToProjects />
            <section className="project-wrapper single-col-max-width py-5 px-4 mx-auto">
                <div className="section-row">
                    <CenteredImage src={img} alt="Media Manager" />
                    <h3 className="section-title">Project Background</h3>
                    <p>Media Manager was an experiment to create a chrome extension that will allow right-clicking on any image/gif/mp4 and allow uploading it to a Pintrest-like site. For example, let's say you came across a funny gif you want to share at work. Instead of managing big lists of bookmarks or saving it locally and shuffling files, you can simply right click any image/gif/mp4 and upload it for easy retrieval.</p>
                    <p>Obviously there are other existing products that already have this functionality like Imgur and Pinterest, but this was still a great opportunity to learn about chrome's context menu support for extensions. Below are some screenshots showing the result.</p>
                    <p>Context menu:</p>
                    <CenteredImage src={imgContextMenu} alt="Media Manager Context Menu" />
                    <p>Upload dialog:</p>
                    <CenteredImage src={imgUpload} alt="Media Manager Upload dialog" />
                    <p>Media Gallery:</p>
                    <CenteredImage src={imgGallery} alt="Media Manager Media Gallery" />
                </div>
            </section>
        </>
    );
}
