import 'bootstrap';
import './assets/css/theme-10.css';
import './assets/css/theme-overrides.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import $ from 'jquery'; // eslint-disable-line
import './assets/plugins/back-to-top';
import ScrollToTop from './components/ScrollToTop';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <BrowserRouter basename={baseUrl}>
        <ScrollToTop>
            <App />
        </ScrollToTop>
    </BrowserRouter>,
    rootElement);

serviceWorker.unregister();
