import React from 'react';
import DefaultLayout from '../DefaultLayout';
import ProjectHeader from './ProjectHeader';
import CenteredImage from '../CenteredImage';
import GoBackToProjects from './GoBackToProjects';

import img from '../../assets/images/projects/mystaywell.png';
import StayWellBackground from './StayWellBackground';
import StyledUl from '../StyledUl';
import StyledLi from '../StyledLi';
import StyledSectionTitle from '../StyledSectionTitle';

export default function MyStayWell(props) {
  return (
    <div>
      <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()}/>
    </div>
  );
}

const getHeader = () => {
  return <ProjectHeader title="Project: MyStayWell Platform"/>;
}

const getContent = () => {
  return (
    <>
      <GoBackToProjects/>
      <section className="project-wrapper single-col-max-width py-5 px-4 mx-auto">
        <div className="section-row">
          <CenteredImage src={img} alt="MyStayWell Platform"/>
          <StayWellBackground/>
          <StyledSectionTitle>MyStayWell Platform</StyledSectionTitle>
          <p>In 2018 StayWell aquired Provata Health and since then we have been working on building out new features on a the new platform and migrating
            existing StayWell Portal clients to the new solution.</p>
          <StyledSectionTitle>Tech Stack Transition</StyledSectionTitle>
          <p>Moving from a .NET monolith-ish application to working on a mac on a microservices application running in AWS with a React front-end was a fun and
            interesting challenge. Some of the tech used on the new platform:</p>
          <StyledUl>
            <StyledLi>Java services using Dropwizard framework</StyledLi>
            <StyledLi>Node Express</StyledLi>
            <StyledLi>React</StyledLi>
            <StyledLi>MySQL</StyledLi>
            <StyledLi>Reddis</StyledLi>
            <StyledLi>Git</StyledLi>
            <StyledLi>AWS</StyledLi>
            <StyledLi>Terraform</StyledLi>
            <StyledLi>iOS</StyledLi>
            <StyledLi>Android</StyledLi>
          </StyledUl>
          <StyledSectionTitle>SAML</StyledSectionTitle>
          <p>One of the first projects I worked on after transitioning to the new platform was to implement the same SAML functionality we had on our old
            platform. This included creating functionality for inbound SAML in addition to writing utility code to generate new outbound implementations. We
            wanted to have the same quality of life tools and utilities as our old application so I also implemented a test harness and test endpoints to make
            new client setup as smooth as possible.</p>
          <p>Our previous platform utilized a SAML library called ComponentSpace, but since that library was .NET based, I used the Java library OpenSAML for
            the new platform.</p>
          <StyledSectionTitle>Environment Variables</StyledSectionTitle>
          <p>One challenge we encountered after moving to the new platform was that the existing codebase used several inconsitent methods of setting and
            accessing environment variables across each of the microservices and across each of the different environments. To solve this problem I created some
            shared utility code to manage access of these settings and relocated all settings to a single location using java annotations to apply various
            options to each setting. This gave us the advantage of being able to see all possible settings in one place and also allowed us to easily log all
            non-secret settings on application initialization for easy debugging. I also worked with our devops team to get existing variables setup in
            terraform for specific environment overrides.</p>
          <StyledSectionTitle>Third Party Incentives Fulfillment Integration</StyledSectionTitle>
          <p>The new StayWell platform had different incentives functionality than the old platform and a contract with a different third party provider for
            fulfillment, but did not yet have any integrated functionality. My responsibility with this project was to gather requirements and work with
            internal stakeholders and the third party developers to create the integration between our applications. The company we integrated with is a
            recognition and rewards platform called <a href="https://fond.co" target="_blank" rel="noopener noreferrer">Fond</a>.</p>
          <p>The main challenge of this project was that Fond had not yet done this type of integration before us, and our incentives functionality was still in
            the process of being updated, so we worked together to build out the functionality on both sides at the same time. Despite the challenge the Fond
            devs were great to work with and we were able to get the SAML login up and running and service callback functionality working to communicate data
            between our applications.</p>
          <StyledSectionTitle>Localization Support</StyledSectionTitle>
          <p>The new platform that we inherited did not have built-in support for localization of dynamic data being moderated by our internal admins, so we
            were tasked with building out this functionality. I took this as a personal challenge to create a method of adding localization support for dynamic
            database records with the smallest amount of code required when new data types are added. I accomplished this by using reflection and custom
            annotations. All a developer has to do now is to annotate a model class and add a few one liners to a few service endpoints that handle the CRUD for
            that particular model. Another developer I worked closely with created the admin UI component of this functionality to allow devs to apply the
            localzation editing to our content management interface.</p>
          <StyledSectionTitle>Cache Client Library</StyledSectionTitle>
          <p>We needed write-invalided caching in several of our microservices, so I wrote a cache client wrapper library for Redis with an API that simplifies
            the process.</p>
          <StyledSectionTitle>Structured Logging Library</StyledSectionTitle>
          <p>Some developers were writing logging code that sometimes threw exceptions while logging exceptions. I wrote a structured logging library
            with an interface that automatically serializes objects and ensures exceptions are not thrown during exception logging.</p>
          <StyledSectionTitle>Authentication Rewrite</StyledSectionTitle>
          <p>While making some configuration changes to our Newrelic integration, I noticed that we were making an unusually large number of requests related to
            authentication and retrieving the current user. In order to alleviate many of these unnecessary service/database hits, I restructured how JWT tokens
            are generated to make usage of them more efficient, in addition to more secure by encrypting the token contents. This reduced approximately 70% of
            all database hits in 2 of our microservices, in addition to drastically reducing the amount of http requests required for a page load.</p>
        </div>
      </section>
    </>
  );
}
