import React from 'react'
import DefaultLayout from '../DefaultLayout'
import ProjectHeader from './ProjectHeader'
import GoBackToProjects from './GoBackToProjects'

import img from '../../assets/images/projects/environment-settings-dotnet.png'
import CenteredImage from '../CenteredImage'

export default function DotnetEnvironmentSettings(props) {
  return (
    <div>
      <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()}/>
    </div>
  )
}

const getHeader = () => {
  return <ProjectHeader title="Project: Dotnet Environment Settings"/>
}

const getContent = () => {
  return (
    <>
      <GoBackToProjects/>
      <section className="project-wrapper single-col-max-width py-5 px-4 mx-auto">
        <div className="section-row">
          <CenteredImage src={img} alt="Word Finder"/>
          <h3 className="section-title">Project Background</h3>
          <p>I fundamentally disagree with the way environment variables are handled in the .NET world. JSON files per environment doesn't make sense. There is no
          way for a new developer on the project to know what each of the settings is, what all the possible values are, what each value's type is at runtime, where
            it's applicable or how it changes between environments. Environment variables should be something that the environment provides. That is why they're
            called environment variables.</p>
          <p>I'm sure part of the reason .NET uses this pattern is because they've always been meant for applications running on windows. Now that this isn't the case (
          I'm running all my .NET websites on Linux), there needs to be a different and better way to pull in environment variables. Every .NET project I've worked on
          has recognized this and has rolled their own wrapper for accessing environment variables.</p>
          <p>We could just write a simple wrapper that reads in environment variables raw. However, pulling in "magic values" at runtime without knowing anything about them, whether there should be a default value, if it's secret vs something
          that just happens to be different per environment, etc etc, is a gigantic problem.</p>
          <p>I wanted to be able to attach meta data to each value to solve all these problems. With this library you can accomplish this by decorating enum values to get the following:</p>
          <ul>
            <li>Default value (if there is one) selectively based on environment</li>
            <li>Differentiate basic environment variables from secrets</li>
            <li>Define whether the value should be logged whenever environemnt settings are logged</li>
            <li>Throw an error if the application starts up and the environemt didn't have the value set in a particular environment</li>
          </ul>
          <p>There are also helper methods to read in values from a .env file and log all values that are safe to log. The structure of the library also facilitates
            easy unit testing via an environment settings interface. You can see this project being used in <a href="https://github.com/mikey-t/dotnet-react-sandbox">dotnet-react-sandbox</a></p>
          <p>Check it out here: <a href="https://github.com/mikey-t/environment-settings-dotnet">environment-settings-dotnet</a>.</p>
        </div>
      </section>
    </>
  )
}
