import React from 'react'
import DefaultLayout from '../DefaultLayout'
import ProjectHeader from './ProjectHeader'
import GoBackToProjects from './GoBackToProjects'

import img from '../../assets/images/projects/flashcards.png'
import CenteredImage from '../CenteredImage'

export default function Flashcards(props) {
  return (
    <div>
      <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()}/>
    </div>
  )
}

const getHeader = () => {
  return <ProjectHeader title="Project: Flashcards"/>
}

const getContent = () => {
  return (
    <>
      <GoBackToProjects/>
      <section className="project-wrapper single-col-max-width py-5 px-4 mx-auto">
        <div className="section-row">
          <CenteredImage src={img} alt="Word Finder"/>
          <h3 className="section-title">Project Background</h3>
          <p>There are several reasons this project exists. My wife was learning to code and we were also looking for a flashcards app to help our kids study.
            There are many flashcard apps out there already, but they were just asking too much money for too little functionality, so we made one ourselves!</p>
          <p>This project provided many great benefits:</p>
          <ul>
            <li>Improved Typescript/React skills</li>
            <li>Learned Material UI (<a href="https://mui.com/">MUI</a>)</li>
            <li>Learned more about progressive web apps (PWA)</li>
            <li>Built some homemade devops tools for automated build and deployment</li>
            <li>Utilized my new DB Migrations concept (<a href="https://github.com/mikey-t/db-migrations-dotnet">db-migrations-dotnet</a>)</li>
            <li>Continued adding to and improving my node CLI utils library (<a href="https://github.com/mikey-t/node-cli-utils">node-cli-utils</a>)</li>
            <li>Learned more about using markdown editors</li>
            <li>Learned more about implementing social logins</li>
            <li>Got to share one of my favorite hobbies (coding) with my wife</li>
            <li>Now have a flashcards app to study things!</li>
          </ul>
          <p>Check it out at <a href="https://flashcards.cookiebrains.com">flashcards.cookiebrains.com</a>!</p>
        </div>
      </section>
    </>
  )
}
