import React from 'react'
import DefaultLayout from '../DefaultLayout'
import ProjectHeader from './ProjectHeader'
import GoBackToProjects from './GoBackToProjects'

import '../../assets/css/carousel-fix.css'

import img from '../../assets/images/projects/countdown-solver.png'
import CenteredImage from '../CenteredImage'

export default function CountdownSolver(props) {
  return (
    <div>
      <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()}/>
    </div>
  )
}

const getHeader = () => {
  return <ProjectHeader title="Project: Countdown Solver"/>
}

const getContent = () => {
  return (
    <>
      <GoBackToProjects/>
      <section className="project-wrapper single-col-max-width py-5 px-4 mx-auto">
        <div className="section-row">
          <CenteredImage src={img} alt="Countdown Solver"/>
          <h3 className="section-title">Project Background</h3>
          <p>I enjoy the british gameshow <a href="https://en.wikipedia.org/wiki/8_Out_of_10_Cats_Does_Countdown">8 Out of 10 Cats Does Countdown</a> because of
            the comedians they have on the show and because the word and number games are fun too. While watching one day, I wondered how hard it would be to
            write an efficient non-brute-force algorithm to find all possible words in a dictionary given a set of letters.</p>
          <p>My high level solution was to pre-process the dictionary into a collection that maps the ordered letters in each word as the hash to lists of words
            made of of the letters in the hash key. When user
            input comes in, I calculate all ordered permutations of the letters (faster than all unordered permutations) and lookup matching words using the
            hash
            map.</p>
          <p>There is also a solver for the number game, but it is still a work in progress (it will find many of the possible solutions, but not all of
            them).</p>
          <p>Check out the result over on <a
            href="https://countdown.mikeyt.net">https://countdown-solver.mikeyt.net</a>.</p>
        </div>
      </section>
    </>
  )
}
