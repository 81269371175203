import React from 'react';
import DefaultLayout from '../DefaultLayout';
import ProjectHeader from './ProjectHeader';
import GoBackToProjects from './GoBackToProjects';

import '../../assets/css/carousel-fix.css';

import imgCanker from '../../assets/images/projects/canker.jpg';
import CenteredImage from '../CenteredImage';
import RumorBlurb from './RumorBlurb';

export default function CankerComplete(props) {
    return (
        <div>
            <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()} />
        </div>
    );
}

const getHeader = () => {
    return <ProjectHeader title="Project: Canker Complete" />;
}

const getContent = () => {
    return (
        <>
            <GoBackToProjects />
            <section className="project-wrapper single-col-max-width py-5 px-4 mx-auto">
                <div className="section-row">
                    <CenteredImage src={imgCanker} alt="Canker Complete" />
                    <h3 className="section-title">Project Background</h3>
                    <p>Goal: build a static informational site, customize a shopping cart and seamlessly integrate the two. </p>
                    <p>Transformed Photoshop documents into a matching custom site and a <a href="https://magento.com/">Magento</a> template.</p>
                    <RumorBlurb />
                </div>
            </section>
        </>
    );
}
