import React from 'react';
import PropTypes from 'prop-types';
import DefaultLayout from './DefaultLayout';
import profileImage from '../assets/images/profile.png';
import {Link} from 'react-router-dom'

export default function Resume(props) {
  return <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()} hideFooterInvite/>
}

const getHeader = () => {
  return (
    <div className="header-intro header-intro-resume theme-bg-primary text-white py-5">
      <div className="container position-relative">
        <h2 className="page-heading mb-3">Resume</h2>
        {/* <a className="btn theme-btn-on-bg download-resume position-absolute font-weight-bold mx-auto" href="https://themes.3rdwavemedia.com/resources/sketch-template/pillar-sketch-sketch-resume-template-for-developers/" target="_blank"><i className="fas fa-download mr-2"></i>Download PDF Version</a> */}
      </div>
    </div>
  );
}

const getContent = () => {
  return (
    <article className="resume-wrapper text-center position-relative">
      <div className="resume-wrapper-inner mx-auto text-left bg-white shadow-lg">
        <header className="resume-header pt-4 pt-md-0">
          <div className="media flex-column flex-md-row">
            <img className="mr-3 img-fluid picture mx-auto" src={profileImage} alt="Mike Thompson"/>
            <div className="media-body p-4 d-flex flex-column flex-md-row mx-auto mx-lg-0">
              <div className="primary-info">
                <h1 className="name mt-0 mb-1 text-white text-uppercase text-uppercase">Mike Thompson</h1>
                <div className="title mb-3">Senior Full Stack Software Engineer</div>
                <ul className="list-unstyled">
                  <li className="mb-2"><a href="mailto:mike@mikeyt.net"><i className="far fa-envelope fa-fw mr-2" data-fa-transform="grow-3"></i>mike@mikeyt.net</a>
                  </li>
                  {/* <li><a href="#"><i className="fas fa-mobile-alt fa-fw mr-2" data-fa-transform="grow-6"></i>0123 456 78900</a></li> */}
                </ul>
              </div>
              <div className="secondary-info ml-md-auto mt-2">
                <ul className="resume-social list-unstyled">
                  <li className="mb-3"><a href="https://www.linkedin.com/in/mike-thompson-101010"><span className="fa-container text-center mr-2"><i
                    className="fab fa-linkedin-in fa-fw"></i></span>linkedin.com/in/mike-thompson-101010</a></li>
                  <li className="mb-3"><a href="https://github.com/mikey-t"><span className="fa-container text-center mr-2"><i
                    className="fab fa-github-alt fa-fw"></i></span>github.com/mikey-t</a></li>
                  <li><a href="https://mikeyt.net"><span className="fa-container text-center mr-2"><i className="fas fa-globe"></i></span>mikeyt.net</a></li>
                </ul>
              </div>

            </div>
          </div>
        </header>
        <div className="resume-body p-5">
          <section className="resume-section summary-section mb-5">
            <h2 className="resume-section-title text-uppercase font-weight-bold pb-3 mb-3">Career Summary</h2>
            <div className="resume-section-content">
              <p className="mb-0">I am a full stack software engineer with over 10 years' experience building rich enterprise web applications. For a detailed
                portfolio and additional details about past projects, see <a href="https://mikeyt.net/projects">mikeyt.net/projects</a>.</p>
            </div>
          </section>
          <div className="row">
            <div className="col-lg-9">
              <section className="resume-section experience-section mb-5">
                <h2 className="resume-section-title text-uppercase font-weight-bold pb-3 mb-3">Work Experience</h2>
                <div className="resume-section-content">
                  <div className="resume-timeline position-relative">
                    <WorkExpItem
                      jobTitle="Senior Software Engineer / Architect"
                      companyName="WebMD Health Services (WHS acquired StayWell July 2020)"
                      dateRangeString="April 2010 - Present"
                      roleDesc="Started as a client implementations developer for an enterprise multi-tenant population health management web application in the healthcare industry. Created and maintained supporting utilities, tools and administrative applications. Moved into a software developer position working on new feature development and platform reskin/restructuring projects. Assumed team lead role for a year and a half. Moved onto the Architecture team in 2019. Moved to the WHS Architecture team when StayWell was acquired in July 2020. Officially promoted to Architect July 2021."
                      achieveIntro="A few highlights (see portfolio for additional detail):"
                      achieveBullets={[
                        'Created web based, console and WPF administration tools to simplify processes',
                        'Worked on complete front-end rewrite',
                        'Worked with certified Sitecore partner to integrate our solution with Sitecore CMS',
                        'Team Lead 2016-2018 for distributed remote team, including offshore resources',
                        'Created extensible inbound and outbound SAML SSO functionality and supporting tools and utilities',
                        'Implemented multiple third party integrations (SAML SSO, OAuth and API integrations)',
                        'Assisted in migration of entire platform to new tech stack'
                      ]}
                      techList={['C#', 'Java', 'Javascript', 'jQuery', 'KnockoutJS', 'HTML', 'CSS', 'React', 'React Native', 'ASP.NET', 'WebForms', 'MVC', 'Sitecore', 'TFS', 'Git', 'MSSQL', 'Oracle', 'MySQL', 'Redis', 'MongoDB', 'ElasticSearch', 'SAML', 'Dropwizard', 'Node Express', 'Microservices', 'AWS', 'iOS', 'Android', 'Visual Studio', 'VSCode', 'IntelliJ', 'Windows', 'Ubuntu', 'MacOS']}
                      seeOnlineLinks/>

                    {/* <WorkExpItem
                          jobTitle="Owner/Freelance Web Developer"
                          companyName="SilverSocket, LLC"
                          dateRangeString="December 2007 – December 2018"
                          roleDesc="Created an LLC for freelance web development jobs."
                          achieveIntro=""
                          achieveBullets={[
                              'Created custom CMS solutions, customized shopping carts, built custom websites from scratch and created custom WordPress plugins and theme components'
                          ]}
                          techList={['C#', 'ASP.NET', 'MSSQL', 'PHP', 'MySQL', 'HTML', 'CSS', 'Javascript', 'WordPress']} /> */}

                    <WorkExpItem
                      jobTitle="Web Developer"
                      companyName="Rumor Advertising"
                      dateRangeString="September 2008 – October 2009"
                      roleDesc="Worked as LAMP developer with clients and in-house designers to create custom websites and CMS solutions."
                      achieveIntro=""
                      achieveBullets={[
                        'Created custom PHP framework to quickly spin up new sites for clients',
                        'Worked closely with designers to transform photoshop designs into unique custom websites'
                      ]}
                      techList={['PHP', 'HTML', 'CSS', 'Javascript', 'MySQL', 'C#', 'ASP.NET', 'Magento', 'ZenCart', 'WordPress']}/>

                    <WorkExpItem
                      jobTitle="Software Engineer"
                      companyName="H.W. Lochner"
                      dateRangeString="June 2008 – September 2008"
                      roleDesc="Helped develop new Lochner intranet CMS and worked on miscellaneous client project websites."
                      techList={['C#', 'ASP.NET', 'MSSQL', 'NHibernate']}/>

                    <WorkExpItem
                      jobTitle="Web Developer"
                      companyName="Evolution Management Group"
                      dateRangeString="January 2007 – November 2007"
                      roleDesc="LAMP developer responsible for maintenance and new development for internal and customer facing web applications."
                      achieveIntro=""
                      achieveBullets={[
                        'Developed content management system for an online company newsletter',
                        'Developed web based reporting applications integrated with call center data',
                        'Contributed to full re-write of primary customer facing web application'
                      ]}
                      techList={['PHP', 'MySQL', 'HTML', 'CSS', 'Javascript']}/>

                    {/* <WorkExpItem
                                            jobTitle=""
                                            companyName=""
                                            dateRangeString=""
                                            roleDesc=""
                                            achieveIntro=""
                                            achieveBullets={[
                                                ''
                                            ]}
                                            techList={['']} /> */}
                  </div>

                </div>
              </section>
            </div>
            <div className="col-lg-3">
              <section className="resume-section skills-section mb-5">
                <h2 className="resume-section-title text-uppercase font-weight-bold pb-3 mb-3">Skills &amp; Tools</h2>
                <div className="resume-section-content">
                  <div className="resume-skill-item">
                    {/* <h4 className="resume-skills-cat font-weight-bold">Frontend</h4> */}
                    {/*<ul className="list-unstyled mb-4">*/}
                    {/*  <BarSkill name="C#" value="95"/>*/}
                    {/*  <BarSkill name="Java" value="90"/>*/}
                    {/*  <BarSkill name="Javascript" value="90"/>*/}
                    {/*  <BarSkill name="MySQL/MSSQL" value="90"/>*/}
                    {/*  <BarSkill name="MongoDB" value="90"/>*/}
                    {/*  <BarSkill name="ASP.NET MVC/Core/WebAPI" value="90"/>*/}
                    {/*  <BarSkill name="HTML" value="85"/>*/}
                    {/*  <BarSkill name="CSS" value="80"/>*/}
                    {/*  <BarSkill name="React" value="85"/>*/}
                    {/*  <BarSkill name="Git" value="80"/>*/}
                    {/*  <BarSkill name="SAML" value="95"/>*/}
                    {/*  <BarSkill name="MSTest/JUnit" value="95"/>*/}
                    {/*  <BarSkill name="Jest/Enzyme" value="80"/>*/}


                    {/* <li className="mb-2">
                                                <div className="resume-skill-name">Angular</div>
                                                <div className="progress resume-progress">
                                                    <div className="progress-bar theme-progress-bar-dark" role="progressbar" style={{ width: "98%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </li>
                                            <li className="mb-2">
                                                <div className="resume-skill-name">React</div>
                                                <div className="progress resume-progress">
                                                    <div className="progress-bar theme-progress-bar-dark" role="progressbar" style={{ width: "94%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </li>
                                            <li className="mb-2">
                                                <div className="resume-skill-name">JavaScript</div>
                                                <div className="progress resume-progress">
                                                    <div className="progress-bar theme-progress-bar-dark" role="progressbar" style={{ width: "96%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </li>

                                            <li className="mb-2">
                                                <div className="resume-skill-name">Node.js</div>
                                                <div className="progress resume-progress">
                                                    <div className="progress-bar theme-progress-bar-dark" role="progressbar" style={{ width: "92%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </li>
                                            <li className="mb-2">
                                                <div className="resume-skill-name">HTML/CSS/SASS/LESS</div>
                                                <div className="progress resume-progress">
                                                    <div className="progress-bar theme-progress-bar-dark" role="progressbar" style={{ width: "96%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </li> */}
                    {/*</ul>*/}
                  </div>

                  {/* <div className="resume-skill-item">
                                        <h4 className="resume-skills-cat font-weight-bold">Backend</h4>
                                        <ul className="list-unstyled">
                                            <li className="mb-2">
                                                <div className="resume-skill-name">Python/Django</div>
                                                <div className="progress resume-progress">
                                                    <div className="progress-bar theme-progress-bar-dark" role="progressbar" style={{ width: "95%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </li>
                                            <li className="mb-2">
                                                <div className="resume-skill-name">Ruby/Rails</div>
                                                <div className="progress resume-progress">
                                                    <div className="progress-bar theme-progress-bar-dark" role="progressbar" style={{ width: "92%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </li>
                                            <li className="mb-2">
                                                <div className="resume-skill-name">PHP</div>
                                                <div className="progress resume-progress">
                                                    <div className="progress-bar theme-progress-bar-dark" role="progressbar" style={{ width: "86%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </li>
                                            <li className="mb-2">
                                                <div className="resume-skill-name">WordPress/Shopify</div>
                                                <div className="progress resume-progress">
                                                    <div className="progress-bar theme-progress-bar-dark" role="progressbar" style={{ width: "82%" }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div> */}

                  <div className="resume-skill-item">
                    {/*<h4 className="resume-skills-cat font-weight-bold">Others</h4>*/}
                    <ul className="list-inline">
                      <BulletSkill name="C#"/>
                      <BulletSkill name="Java"/>
                      <BulletSkill name="JavaScript"/>
                      <BulletSkill name="Typescript"/>
                      <BulletSkill name="MySQL"/>
                      <BulletSkill name="MSSQL"/>
                      <BulletSkill name="MongoDB"/>
                      <BulletSkill name="ASP.NET MVC"/>
                      <BulletSkill name=".NET Core"/>
                      <BulletSkill name="HTML"/>
                      <BulletSkill name="CSS"/>
                      <BulletSkill name="React"/>
                      <BulletSkill name="Git"/>
                      <BulletSkill name="Saml"/>
                      <BulletSkill name="MSTest"/>
                      <BulletSkill name="JUnit"/>
                      <BulletSkill name="Jest"/>
                      <BulletSkill name="Enzyme"/>
                      <BulletSkill name="Mocha"/>
                      <BulletSkill name="VSCode"/>
                      <BulletSkill name="Node"/>
                      <BulletSkill name="jQuery"/>
                      <BulletSkill name="KnockoutJS"/>
                      <BulletSkill name="Sitecore"/>
                      <BulletSkill name="PHP"/>
                      <BulletSkill name="Oracle"/>
                      <BulletSkill name="Sqlite"/>
                      <BulletSkill name="ElasticSearch"/>
                      <BulletSkill name="Dropwizard"/>
                      <BulletSkill name="Microservices"/>
                      <BulletSkill name="Magento"/>
                      <BulletSkill name="ZenCart"/>
                      <BulletSkill name="Express"/>
                      <BulletSkill name="React Native"/>
                      <BulletSkill name="AWS"/>
                      <BulletSkill name="TFS"/>
                      <BulletSkill name="Chrome Extensions"/>
                      <BulletSkill name="Shell"/>
                      <BulletSkill name="WordPress"/>
                      <BulletSkill name="XML"/>
                      <BulletSkill name="DI"/>
                      <BulletSkill name="Python"/>
                      <BulletSkill name="Redis"/>

                      {/* <li className="list-inline-item"><span className="badge badge-light">DevOps</span></li>
                                            <li className="list-inline-item"><span className="badge badge-light">Code Review</span></li>
                                            <li className="list-inline-item"><span className="badge badge-light">Git</span></li>
                                            <li className="list-inline-item"><span className="badge badge-light">Unit Testing</span></li>
                                            <li className="list-inline-item"><span className="badge badge-light">Wireframing</span></li>
                                            <li className="list-inline-item"><span className="badge badge-light">Sketch</span></li>
                                            <li className="list-inline-item"><span className="badge badge-light">Balsamiq</span></li>
                                            <li className="list-inline-item"><span className="badge badge-light">WordPress</span></li>
                                            <li className="list-inline-item"><span className="badge badge-light">Shopify</span></li> */}
                    </ul>
                  </div>
                </div>
              </section>
              {/* <section className="resume-section education-section mb-5">
                                <h2 className="resume-section-title text-uppercase font-weight-bold pb-3 mb-3">Education</h2>
                                <div className="resume-section-content">
                                    <ul className="list-unstyled">
                                        <li className="mb-2">
                                            <div className="resume-degree font-weight-bold">MSc in Computer Science</div>
                                            <div className="resume-degree-org">University College London</div>
                                            <div className="resume-degree-time">2010 - 2011</div>
                                        </li>
                                        <li>
                                            <div className="resume-degree font-weight-bold">BSc Maths and Physics</div>
                                            <div className="resume-degree-org">Imperial College London</div>
                                            <div className="resume-degree-time">2007 - 2010</div>
                                        </li>
                                    </ul>
                                </div>
                            </section> */}
              <section className="resume-section education-section mb-5">
                <h2 className="resume-section-title text-uppercase font-weight-bold pb-3 mb-3">Education</h2>
                <div className="resume-section-content">
                  <ul className="list-unstyled">
                    <li className="mb-2">
                      {/* <div className="resume-degree font-weight-bold">MSc in Computer Science</div> */}
                      <div className="resume-degree-org">University of Utah</div>
                      <div className="resume-degree-time">2005 - 2008</div>
                      <div className="">Completed core coursework for BS in computer science</div>
                    </li>
                  </ul>
                </div>
              </section>
              <section className="resume-section reference-section mb-5">
                <h2 className="resume-section-title text-uppercase font-weight-bold pb-3 mb-3">Certifications</h2>
                <div className="resume-section-content">
                  <ul className="list-unstyled resume-awards-list">
                    <li className="mb-2 pl-4 position-relative">
                      <i className="resume-award-icon fas fa-trophy position-absolute" data-fa-transform="shrink-2"></i>
                      <div className="resume-award-name">Certified Ethical Hacker</div>
                      <div className="resume-award-desc">EC-Council Certified – 2011</div>
                    </li>
                    <li className="mb-0 pl-4 position-relative">
                      <i className="resume-award-icon fas fa-trophy position-absolute" data-fa-transform="shrink-2"></i>
                      <div className="resume-award-name">MCSD</div>
                      <div className="resume-award-desc">Microsoft Certified Solutions Developer 2015 MC ID 12066987</div>
                    </li>
                  </ul>
                </div>
              </section>
              {/* <section className="resume-section language-section mb-5">
                                <h2 className="resume-section-title text-uppercase font-weight-bold pb-3 mb-3">Language</h2>
                                <div className="resume-section-content">
                                    <ul className="list-unstyled resume-lang-list">
                                        <li className="mb-2"><span className="resume-lang-name font-weight-bold">English</span> <small className="text-muted font-weight-normal">(Native)</small></li>
                                        <li className="mb-2 align-middle"><span className="resume-lang-name font-weight-bold">French</span> <small className="text-muted font-weight-normal">(Professional)</small></li>
                                        <li><span className="resume-lang-name font-weight-bold">Spanish</span> <small className="text-muted font-weight-normal">(Professional)</small></li>
                                    </ul>
                                </div>
                            </section> */}
              <section className="resume-section interests-section mb-5">
                <h2 className="resume-section-title text-uppercase font-weight-bold pb-3 mb-3">Interests</h2>
                <div className="resume-section-content">
                  <ul className="list-unstyled">
                    <li className="mb-1">Gaming</li>
                    <li className="mb-1">Coding</li>
                    <li className="mb-1">Basketball</li>
                    <li className="mb-1">Biking</li>
                  </ul>
                </div>
              </section>

            </div>
          </div>
        </div>

      </div>
    </article>
  );
}

const WorkExpItem = (props) => {
  return (
    <article className="resume-timeline-item position-relative pb-5">
      <div className="resume-timeline-item-header mb-2">
        <div className="d-flex flex-column flex-md-row">
          <h3 className="resume-position-title font-weight-bold mb-1">{props.jobTitle}</h3>
          <div className="resume-company-name ml-auto">{props.companyName}</div>
        </div>
        <div className="resume-position-time">{props.dateRangeString}</div>
      </div>
      <div className="resume-timeline-item-desc">
        <p>{props.roleDesc}</p>
        {(!!props.achieveIntro || !!props.achieveBullets) && <h4 className="resume-timeline-item-desc-heading font-weight-bold">Achievements:</h4>}
        {props.achieveIntro && <p>{props.achieveIntro}</p>}
        {props.achieveBullets && <ul>
          {props.achieveBullets.map((text, i) => <li key={i}>{text}</li>)}
          {props.seeOnlineLinks && <li>For more details, see my project pages: <Link to="/projects/staywell-online">StayWell Online</Link>, <Link to="/projects/my-staywell">My StayWell</Link> and <Link to="/projects/whs">WebMD Health Services</Link></li>}
        </ul>}
        <h4 className="resume-timeline-item-desc-heading font-weight-bold">Technologies used:</h4>
        <ul className="list-inline">
          {props.techList.map((text, i) => <li key={i} className="list-inline-item"><span className="badge badge-secondary badge-pill">{text}</span></li>)}
          
        </ul>
      </div>
    </article>
  );
}

WorkExpItem.propTypes = {
  jobTitle: PropTypes.string.isRequired,
  companyName: PropTypes.string.isRequired,
  dateRangeString: PropTypes.string.isRequired,
  roleDesc: PropTypes.string.isRequired,
  achieveIntro: PropTypes.string,
  achieveBullets: PropTypes.arrayOf(PropTypes.string),
  techList: PropTypes.arrayOf(PropTypes.string).isRequired
};

const BarSkill = (props) => {
  return (
    <li className="mb-2">
      <div className="resume-skill-name">{props.name}</div>
      <div className="progress resume-progress">
        <div className="progress-bar theme-progress-bar-dark" role="progressbar" style={{width: `${props.value}%`}} aria-valuenow={props.value}
             aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </li>
  );
}

const BulletSkill = (props) => {
  return (
    <li className="list-inline-item"><span className="badge badge-light">{props.name}</span></li>
  );
}
