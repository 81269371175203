import React from 'react'
import DefaultLayout from '../DefaultLayout'
import ProjectHeader from './ProjectHeader'
import GoBackToProjects from './GoBackToProjects'

import img from '../../assets/images/projects/project-omega.png'
import CenteredImage from '../CenteredImage'

export default function ProjectOmega(props) {
  return (
    <div>
      <DefaultLayout location={props.location} headerChildren={getHeader()} contentChildren={getContent()}/>
    </div>
  )
}

const getHeader = () => {
  return <ProjectHeader title="Project: Project Omega"/>
}

const getContent = () => {
  return (
    <>
      <GoBackToProjects/>
      <section className="project-wrapper single-col-max-width py-5 px-4 mx-auto">
        <div className="section-row">
          <CenteredImage src={img} alt="Word Finder"/>
          <h3 className="section-title">Project Background</h3>
          <p>When the company I worked at purchased another company (Staywell bought Provata), we shifted our entire platform from a monolith application to a
            microservices architecture. During this process I learned all about the benefits and pitfalls of microservices. I have a lot of opinions on the
            subject
            , including an idea for a monolith microservices hybrid that would, in my opinion, be a significantly better fit for most companies looking to
            utizlie
            a microservices architecture.</p>
          <p>You can read all about it as well as watch a couple of demo videos over on the project page: <a
            href="https://github.com/mikey-t/project-omega">project-omega</a></p>
          <p>I have plans in the near future to resurect and recreate this project. I would like to clean up the hooks concept, upgrade the tech stack, more
            cleanly separate
            the UI from the other service projects (similar to what I've done in my <a
              href="https://github.com/mikey-t/dotnet-react-sandbox">dotnet-react-sandbox</a> project)
            and integrate some of the other new concepts and libraries I've been working on over the past year. It would also be nice to rename it to something
            less cheesy. When I originally created the project I thought "Project Omega" was funny because a) every developer thinks their new idea is the best
            and last idea and it never is, and b) each new microservice was a Project Omega Service, or a P.O.S.). However, I'd like to name this something that
            matches up with what it actually is, like perhaps "Modular Monolith" or "Microservices Monlith Hybrid". Suggestions are welcome!</p>
        </div>
      </section>
    </>
  )
}
